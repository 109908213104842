@import 'styles/config';

$ProfilesPageSearch-height-xs: 70px;
$ProfilesPageSearch-height-sm: 50px;

.sticky-filter-row {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: white;
}

.ProfilesPageSearch {
    position: relative;
    display: flex;
    align-items: center;
    background: $gray-F9;
    height: $ProfilesPageSearch-height-xs;
    padding: 23px;
    border-radius: 8px;
    width: 100%;

    &__content {
        width: 100%;
        z-index: 9999;
        cursor: pointer;
        background: white;
        top: 105%;
        position: absolute;
        left: 0;
        border-radius: 8px;
        max-height: 338px;
        overflow-y: auto;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);

        &__row {
            display: flex;
            align-items: center;
            column-gap: 20px;
        }
    }

    @media screen and (max-width: 768px) {
        width: calc(100% - 53px);
    }

    &__icon {
        height: 27px !important;
        width: 27px !important;

        path {
            fill: $calendar-header-black;
        }
    }

    &__input {
        border: none !important;
        background: transparent !important;
        box-shadow: none !important;
        font-size: 18px !important;
        line-height: 24px !important;
        font-family: $font-family-sans-serif;

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $calendar-header-black;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $calendar-header-black;
        }

        &::placeholder {
            color: $calendar-header-black;
        }

        &:focus {
            background: transparent;
        }
    }
}

.FilterIcon {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.btn.IconFilter {
    display: none;
    padding: 0;
    height: $ProfilesPageSearch-height-xs;

    @media screen and (max-width: 426px) {
        height: $ProfilesPageSearch-height-sm;
        display: inline-block;
        width: 20%;

        img {
            width: 18px;
            height: 18px;
        }
    }
}

@media screen and (max-width: 768px) {
    .mobileSearchBox {
        padding-left: 20px;
        padding-right: 20px;
    }

    .ProfilesPageSearch {
        display: flex;
        align-items: center;
        background: $gray-F9;
        height: $ProfilesPageSearch-height-sm;
        padding: 10px;
        // TODO: restore after adding filter overlay
        //width: 80%;
        border-radius: 8px;

        &__icon {
            height: 18px !important;
            width: 18px !important;

            path {
                fill: $calendar-header-black;
            }
        }

        &__input {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            font-size: 14px !important;
            line-height: 24px !important;
            padding-left: 10px !important;
            padding-right: 0 !important;

            // padding: 0px !important;
            font-family: $font-family-sans-serif;

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $calendar-header-black;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $calendar-header-black;
            }

            &::placeholder {
                color: $calendar-header-black;
            }

            &:focus {
                background: transparent;
            }
        }
    }
}