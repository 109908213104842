@import 'styles/config';

.DaySelect {
  position: relative;
  height: 90px;
  overflow: hidden;
  display: flex;

  &__nav-btn {
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 $spacer * .5;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &--disabled {
      cursor: default;
    }
  }

  &__nav-btn-icon {
    width: $spacer * 1.5;
  }

  &__day {
    color: gray;
    height: 71px;
    width: 52px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &:hover {
      background-color: $calendar-blue;
      color: $white;
      cursor: pointer;
    }

    &--inactive {
      background-color: $gray-200;
    }

    &--active {
      color: $white;
      background-color: $calendar-blue;
    }

    &--disabled {
      background-color: $gray-200 !important;
      color: gray !important;

      &:hover {
        cursor: default;
      }
    }

    &--invalid {
      border: 1px solid $danger;
    }
  }

  &__days-wrapper {
    position: relative;
    overflow: scroll;
    height: 100px;
    flex-grow: 1;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__days-content {
    width: fit-content;
    position: absolute;
    display: flex;
  }
}
