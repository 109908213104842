@import 'styles/config';

$AppFooter__padding-x: $grid-gutter-width * 0.5;
$AppFooter__padding-x-sm: 2.2rem;
$AppFooter__padding-x-md: 3.125rem;
$AppFooter__padding-x-lg: 8.75rem;

.AppFooter {
    &__HeadingFont {
        font-family: $font-family-headings !important;
    }

    &__LogoSection {
        margin-right: 10px;
    }

    // &__middleSection {
    //     margin: 0px 40px;
    // }

    &__Logo-info-box {
        display: flex;
        flex-direction: column;
    }

    &__social {
        display: flex;
        column-gap: 20px;
        margin-top: 1.125rem;
        @media screen and (min-width: 1600px) {
            margin-top: 3.4375rem;
        }
    }

    &__section {
        margin-bottom: 30.5px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 120.5px;
        }

        &--logo {
            background-color: #ffffff;
            text-align: center;
        }
    }

    &__section-middle,
    &__section-bottom {
        padding-left: $AppFooter__padding-x !important;
        padding-right: $AppFooter__padding-x !important;

        @include media-breakpoint-up(sm) {
            padding-left: $AppFooter__padding-x-sm !important;
            padding-right: $AppFooter__padding-x-sm !important;
        }

        @include media-breakpoint-up(md) {
            padding-left: $AppFooter__padding-x-md !important;
            padding-right: $AppFooter__padding-x-md !important;
        }

        @include media-breakpoint-up(xxxl) {
            padding-left: $AppFooter__padding-x-lg !important;
            padding-right: $AppFooter__padding-x-lg !important;
        }
    }

    &__section-middle {
        background-color: #f2f2f2;
        max-width: 100% !important;
        padding-top: 1.25rem !important;
        padding-bottom: 2.25rem !important;
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
        flex-direction: column;
        gap: 30px;

        @include media-breakpoint-up(sm) {
            padding-top: 7.5rem !important;
            padding-bottom: 2.2rem !important;
        }

        @include media-breakpoint-up(md) {
            padding-top: 6.25rem !important;
            padding-bottom: 3.25rem !important;
        }

        @include media-breakpoint-up(xxxl) {
            padding-top: 7.5rem !important;
            padding-bottom: 5rem !important;
        }
    }

    &__nav-link-column {
        margin-bottom: 2 * $spacer;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    &__heading {
        text-align: left;
        margin-bottom: 0.1875rem;
        font-size: 16px;
        line-height: 33px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 1.125rem;
            font-size: 20px;
            line-height: 30px;
        }

        @include media-breakpoint-up(xxxl) {
            margin-bottom: 3.4375rem;
            font-size: 30px;
            line-height: 40px;
        }
    }

    &__contact-info {
        font-size: 1.2rem;
    }

    &__nav-link.nav-link {
        color: $calendar-header-black;
        font-family: $font-family-sans-serif;
        padding: 0.4375rem 0;
        font-size: $font-size-sm;
        line-height: 1.5;

        @include media-breakpoint-up(sm) {
            font-size: $font-size-base;
            line-height: 24px;
            padding: 0.75rem 0;
        }

        &:hover,
        &:focus {
            color: $calendar-black;
        }
    }

    &__tallBR {
        margin-bottom: 14px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 24px;
        }
    }

    &__text {
        font-size: $font-size-sm;
        line-height: 24px;

        @include media-breakpoint-up(sm) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__brand {
        max-width: 211.65px;
        max-height: 69px;

        @include media-breakpoint-up(sm) {
            max-width: 334.44px;
            max-height: 69px;
        }
    }

    &__info-text {
        margin-top: 17px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        max-width: 470px;

        @include media-breakpoint-up(sm) {
            margin-top: 32.8px;
            font-size: $font-size-sm;
            line-height: 28px;
            letter-spacing: 0.5px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 52.8px;
            letter-spacing: 1px;
        }

        @include media-breakpoint-up(xxxl) {
            margin-top: 52.8px;
            letter-spacing: 0.5px;
        }
    }

    &__grey-text {
        color: $calendar-header-black;
        padding-top: 20px;

        @include media-breakpoint-up(sm) {
            color: $calendar-header-black;
        }
    }

    @include media-breakpoint-down(md) {
        &__heading &__contact-info {
            text-align: center;
        }
    }
}
