@import 'styles/config';

%step-title {
    font-family: $font-family-headings;
    font-size: 2.225rem;
    @include media-breakpoint-down(lg) {
        font-size: 2.025rem;
    }
}

%step-subtitle {
    font-family: $font-family-sans-serif;

    @include media-breakpoint-down(lg) {
        font-size: 1.075rem;
    }
}

.howitworks_space {
    margin-bottom: 6.5rem;
    @media screen and (max-width: 1920px) {
        margin-bottom: 6rem;
    }
    @media screen and (max-width: 1440px) {
        margin-bottom: 5.5rem;
    }
    @media screen and (max-width: 1240px) {
        margin-bottom: 2rem;
    }
}
.howitworks_count {
    font-size: 50px;
    font-family: $font-family-headings;
}

.HowItWorks {
    // padding-left: 148px;
    // padding-right: 148px;
    &__steps {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__slide1 {
        margin-top: 45px;
        @media screen and (max-width: 1024px) {
            margin-top: 20px;
        }
    }

    &__slide2 {
        margin-top: 120px;
        @media screen and (max-width: 1024px) {
            margin-top: 20px;
        }
    }

    &__step {
        //min-width: 90px;
        //height: 90px;
        //border-radius: 100%;
        //border: solid 8px #02C3EE;

        justify-content: center;
        align-items: center;
        position: unset;
        transform: translate(-5%, 0);
        @extend %step-title;
    }

    &__step-subtitle {
        margin-top: 60px;
        min-height: 90px;
        max-width: 400px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        @extend %step-subtitle;
        @media screen and (max-width: 1920px) {
            font-size: 24px;
        }
        @media screen and (max-width: 1440px) {
            font-size: 18px;
            margin-top: -24px;
        }
    }

    &__MiddleCol {
        margin-top: -1.8rem;
    }

    &__SideCol {
        margin-top: -5rem;
    }

    &__content {
        margin-top: 102px;

        justify-content: center;
    }
    &__content_shadow_card {
        border: 1px solid #f9f9f9;
        box-shadow: 12px 12px 60px rgb(0 0 0 / 6%);
        padding-bottom: 1.5rem;
    }

    &__center {
        position: relative;
        left: 50%;
        font-weight: 800;
        font-size: 50px;
        transform: translate(-50%, 0);


        @media screen and (max-width: 1920px) {
            font-size: 40px;
            margin-bottom: 55px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 38px;
            margin-bottom: 45px;
        }
    }

    &__img-works {
        position: absolute;
        @media screen and (max-width: 1024px) {
            width: 50px !important;
            height: 50px !important;
        }
    }

    &--slideImg {
        z-index: -1;
        margin-top: -50px;
    }

    &__BG-Lines {
        width: 100%;
        margin-bottom: -180px;
        @media screen and (max-width: 1024px) {
            margin-bottom: -50px;
        }
    }

    &__white-text {
        color: white;
        @media screen and (max-width: 1024px) {
            font-size: 24px;
        }
    }

    &__BG {
        background-image: '/assets/homepage/HowItWorks/lines.png';
    }
}

// @media screen and (max-width:526px) {

//   .HowItWorks {
//     &__steps {
//       align-items: center;
//     }

//     &__step {
//       //min-width: 90px;
//       //height: 90px;
//       //border-radius: 100%;
//       //border: solid 8px #02C3EE;

//       justify-content: center;
//       align-items: center;
//       position: relative;
//       left: 50%;
//       transform: translate(-50%,0);

//       @extend %step-title;
//     }

//     &__step-subtitle {
//      margin-top: 60px !important;
//       min-height: 90px;
//       max-width: 400px;
//       position: relative;
//       font-size: 30px;
//       left: 50%;
//       transform: translate(-50%,0);
//       @extend %step-subtitle;

//     }

//     &__MiddleCol{
//       margin-top: -1.8rem;
//     }

//     &__SideCol{
//       margin-top: -5rem;
//     }

//     &__content {
//       margin-top: 102px;
//       justify-content: center;
//     }
//     &__content_shadow_card{
//       border: 1px solid #f9f9f9;
//       box-shadow: 12px 12px 60px rgb(0 0 0 / 6%);
//       padding-bottom: 1.5rem;
//     }

//     &__center{
//       position: relative;
//       left: 50%;
//       font-weight: 800;
//       font-size: 24px !important;
//       transform: translate(-50%,0);

//     }

//     &__img-works{
//       position: absolute;
//       left: 50%;
//       transform: translate(-50%,0);
//       // margin-bottom: 2.6rem !important;

//     }

//     &__BG-Lines{
//       width: 100%;
//     }

//     &__white-text{
//       color: white;
//     }

//     &__BG{
//       background-image: "/assets/homepage/HowItWorks/lines.png";
//     }
//   }
// }
