.ListDisign {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    li {
        margin-top: 17.5px;
        padding-left: 1.5rem;
    }
}
.margin-bottom-6{
    margin-bottom: 6rem;
}
