@import 'styles/config';

.AuthPage {
  padding-top: 97.5px;

  &__title {
    text-align: center;
    margin-bottom: 52px;
    font-size: 50px;
    line-height: 67px;
    color: $calendar-black;
    font-family: $font-family-headings;
  }

  &__description {
    font-size: 18px;
    font-family: $font-family-sans-serif;
    line-height: 24px;
    margin: auto;
    margin-bottom: 50px;
    color: $calendar-black;
    max-width: 400px;
    text-align: center;
  }

  &__card-body {
    padding: ($spacer * 2) ($spacer * 10) !important;
  }

  &__terms {
    max-width: 400px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    color: $calendar-black;
    margin: 1.65625rem auto 94.2px auto;

    a {
      text-decoration: underline;
    }
  }

  &__form-group {
    margin-bottom: 31.3px !important;
    text-align: left;
  }

  &__input {
    padding: .45rem 1.5rem !important;
    height: 60px !important;
    font-size: 18px !important;
    border-radius: 13px !important;
    box-shadow: none !important;
    border: 1px solid $gray-E9 !important;

    &:-webkit-autofill,
    &:-webkit-autofill:active,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background: $white;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }

    .form-control:focus,
    .form-control:active {
      background: transparent;
    }

    &--password {
      margin: 0 !important;
      height: 58px !important;
      border: none !important;
      box-shadow: none;
    }
  }

  textarea.AuthPage__input {
    height: auto !important;
  }

  &__password-input-group {
    padding: 0 22px 0 0;
    margin: auto;
    height: 60px !important;
    font-size: 18px !important;
    border-radius: 13px !important;
    box-shadow: none !important;
    border: 1px solid $gray-E9 !important;
    display: flex !important;
    align-items: center !important;

    &--disabled {
      background-color: $input-disabled-bg;
    }
  }

  &__button {
    max-width: 400px;
    width: 100%;
    height: 60px;
    border-radius: 8px !important;
    box-shadow: 0 10px 10px rgb(0 0 0 / 16%) !important;
    text-transform: uppercase;
  }

  &__container {
    &--sm {
      max-width: (400px + $grid-gutter-width) !important;
    }

    &--md {
      max-width: 54.25rem !important;
    }

    &--lg {
      max-width: (840px + $grid-gutter-width) !important;
    }
  }
}
