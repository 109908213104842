@import 'styles/config';

.RangeSlider {
    .input-range__track--active {
        background: $calendar-blue;
    }

    .input-range__slider {
        background: $calendar-blue;
        border: 1px solid $calendar-blue;
    }
}

.input-range__label--max {
    display: none;
}

.input-range__label--min {
    display: none;
}

.input-range__label--value {
    top: 0.6rem;
}

@media screen and (max-width: 426px) {
    .RangeSlider {
        width: 90% !important;

        .input-range__track--active {
            background: $calendar-blue;
        }

        .input-range__slider {
            background: $calendar-blue;
            border: 1px solid $calendar-blue;
        }
    }
}
