@import 'styles/config';

.add_resources {
    height: 26px;
    width: 26px;
    position: absolute;
    top: 0px;
    right: 40px
}

.ProfilePageAbout {
    text-align: center;
    border-radius: 4px;
    margin-bottom: $spacer * 3;
    margin-left: 5%;
    margin-right: 5%;

    &--empty {
        align-items: center;
        font-size: 1.25rem;
        color: #788b9a;
        text-align: center;
        margin-bottom: 70px;
    }

    &__heading--title {
        font-size: 14px;
        line-height: 21px;
        color: $calendar-black;
        font-family: $font-family-sub-headings;
    }

    &__image-upload {
        position: relative;

        &:hover {
            .ProfilePageAbout__image-upload--layer {
                display: flex;
            }
        }

        &--placeholder {
            font-size: 16px;
            line-height: 25px;
            color: $calendar-header-black;
            font-family: $font-family-sans-serif;
        }

        &--layer {
            position: absolute;
            height: 4.875rem;
            width: 4.875rem;
            top: 0;
            left: 0;
            display: none;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 50%;
            border: 2px solid rgba(255, 255, 255, 0.2);

            svg {
                path {
                    fill: #ffffff;
                }
            }
        }
    }

    &__selector {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        padding: 11px 0 !important;
        display: flex !important;
        align-items: center !important;

        &:focus,
        &:hover {
            outline: none !important;
            color: #495057;
            text-decoration: none;
        }
    }

    &__Tag-selector {
        padding-bottom: 9px;

        .TagSelector {
            &__control {
                border: none !important;
            }
        }

        .TagSelector__dropdown-menu {
            z-index: 2;
        }
    }

    &__input {
        border-radius: 13px !important;
        border: 1px solid $gray-E9 !important;
        padding: 22px 29px !important;

        &::-webkit-scrollbar {
            width: 0;
            /* Remove scrollbar space */
            background: transparent;
            /* Optional: just make scrollbar invisible */
        }

        /* Optional: show position indicator in red */
        &::-webkit-scrollbar-thumb {
            background: #ff0000;
        }
    }

    &__button {
        &--save-button {
            height: 3.45rem;
            width: 16.5rem;
            border-radius: 0.8rem !important;
            box-shadow: none !important;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 18px;
        margin-top: 64px;

        @include media-breakpoint-up(sm) {
            font-size: 50px;
            margin-bottom: 40px;
            margin-top: 122px;
        }
    }

    &__margin {
        &--a25 {
            margin: 25px;
        }

        &--t15 {
            margin-top: 15px;
        }

        &--b40 {
            margin-bottom: 40px;
        }

        &--t122 {
            margin-top: 122px;
        }
    }

    &__content {
        text-align: justify;

        @media screen and (max-width: 1440px) {
            font-size: 14px;
        }


    }
}

.AboutmeIMG {
    width: 280px;
    height: 344px;
}

@media screen and (max-width: 426px) {
    .ProfilePageAbout {
        &__title {
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 18px;
            margin-top: 64px;
        }

        &__content {
            text-align: justify;
            font-size: 14px;
        }
    }

    .AboutmeIMG {
        width: 150px;
        height: 200px;
    }

    .NoData {
        font-size: 14px;
    }
}

@media screen and (max-width: 345px) {
    .ProfilePageAbout {
        &__content {
            text-align: justify;
            font-size: 13px;
        }
    }
}

@media screen and (max-width: 576px) {
    .NoData {
        font-size: 14px;
    }
}