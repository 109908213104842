@import 'styles/config';

.HelpCenterCategoryList {
  margin-bottom: 5rem;

  &__card {
    cursor: pointer;
    margin-bottom: 2.5rem;
  }
}
