@import 'styles/config';

.AppFooterSubscribeForm {
  margin: $spacer * 4 0;

  @include media-breakpoint-up(sm) {
    margin: 7rem 0;
  }

  &__heading {
    font-weight: 800;
    font-size: 50px;
    margin-bottom: 65px;
    text-align: center;

    @media screen and (max-width: 1920px) {
        font-size: 40px;
        margin-bottom: 55px;
    }

    @media screen and (max-width: 1440px) {
        font-size: 38px;
        margin-bottom: 45px;
    }
}

  &__bot-trap {
    position: absolute;
    left: -5000px;
  }

  &__main {
    display: flex !important;
    width: 100%;
  }

  &__input {
    border: 2px solid #e9e9e9 !important;
    border-radius: 13px !important;
    min-width: 446px !important;
    min-height: 60px;
    box-shadow: none !important;

    @media screen and (max-width: 1000px) {
      min-width: 146px !important;
      min-height: 50px;
    }

    @media screen and (max-width: 450px) {
      min-width: 120px !important;
      min-height: 40px;
      font-size: 16px !important;
      border-radius: 9px !important;
    }
  }

  &__btn-main {
    margin-left: -24px;
  }

  &__button {
    box-shadow: none !important;
    margin-left: -24px;
    border-radius: 13px !important;
    @include button-size($btn-padding-y * 0, $btn-padding-x * 1.25, 1.25rem !important, 1.5, 0.5rem);
    font-family: $font-family-headings !important;
    min-width: 154px;
    min-height: 60px;
    z-index: 10 !important;
    background: linear-gradient(270deg,#01c8ee 0,#0a8bf2);
    background-clip: padding-box;
    border: hidden;
    color: #fff;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;

    &:hover {
      color: #fff;
      background: linear-gradient(270deg,#01a8c7 0,#0876cd);
    }

    @media screen and (max-width: 1000px) {
      min-width: 144px !important;
      min-height: 50px;
    }

    @media screen and (max-width: 450px) {
      min-width: 80px !important;
      min-height: 40px;
      font-size: 16px !important;
      border-radius: 9px !important;
    }
  }
}
