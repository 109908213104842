@import 'styles/config';

$MediaCardSlider-spacer: 0.625rem;

.MediaCardSlider {
  &__carousel {
    overflow: hidden;
  }

  &__nav {
    font-size: 1.6875rem;
    text-decoration: none !important;

    &--prev {
      padding-right: $MediaCardSlider-spacer;
    }

    &--next {
      padding-left: $MediaCardSlider-spacer;
    }
  }
}
