@import 'styles/config';

.BookingModal {
    &__label {
        font-size: 18px !important;
        color: $calendar-header-black;
        font-weight: normal !important;
        margin-bottom: $spacer;
    }

    &__submit-button {
        width: 310px;
        height: 55px;
        font-size: 16px;
        border-radius: 13px !important;
        background: linear-gradient(90deg, rgba(10, 139, 242, 1) 0%, rgba(1, 200, 238, 1) 100%);
        font-weight: bold !important;
        font-family: $font-family-sans-serif;
    }

    &__title {
        font-weight: 100 !important;
        font-size: 20px !important;
        font-style: $font-family-sans-serif !important;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 51px;
    }

    &__textarea {
        padding: 1rem 1.5rem !important;
        width: 100% !important;
        height: 100px;
        margin: auto;
        font-size: 18px !important;
        border-radius: 13px !important;
        box-shadow: none !important;
        color: #495057;

        @include placeholder {
            color: $calendar-header-black !important;
            opacity: 1 !important;
        }
    }

    &__project-search-control {
        width: 100%;
        margin: 8px 0;
        padding: 0.45rem 1.5rem !important;
        margin-bottom: 31.3px !important;
        height: 60px !important;
        font-size: 18px !important;
        border-radius: 13px !important;
        box-shadow: none !important;
        border: 1px solid #e9e9e9 !important;

        @include placeholder {
            color: $calendar-header-black !important;
            opacity: 1 !important;
        }
    }
}

.SkeletonModal {
    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
        width: 100%;
        column-gap: 15px;
        &__profile {
            height: 78px;
            width: 78px;
        }
        &__heading {
            height: 100%;
            width: 500px;
        }
        &__timing {
            height: 100%;
            width: 116px;
        }
    }

    &__deviderLine {
        margin-top: 12px;
        height: 1px;
        width: 100%;
        background-color: $gray-300;
    }

    &__timeslote {
        margin: 16px 0px;
        display: flex;
        flex-direction: column;
        &__selectiontime {
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }
        &__timeslot {
            margin-top: 15px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            gap: 5px;
            overflow: hidden;
            &__rightArrow {
                z-index: 99;
                height: 100%;
                display: flex;
                align-items: center;
                padding-left: 5px;
                background: white;
            }
        }
    }
}

.BookingModalConfirmationSelection {
    &--text {
        margin-bottom: 2.1rem;
        margin-top: 0.5rem;
    }
}