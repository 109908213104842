@import 'styles/config';

.HomeAboutpage {
    width: 100%;

    &--title {
        font-weight: 800;
        font-size: 50px;
        margin-bottom: 65px;
        text-align: center;

        @media screen and (max-width: 1920px) {
            font-size: 40px;
            margin-bottom: 55px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 38px;
            margin-bottom: 45px;
        }
    }

    &--cardcontain {
        .slick-initialized .slick-slide {
            padding: 0px 10px;
        }
    }

    &--text {
        font-size: 20px;
        margin-bottom: 62px;
        color: $calendar-header-black !important;
        text-align: justify;

        @media screen and (max-width: 1920px) {
            font-size: 18px;
            line-height: 22px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &--box {
        border: solid 1px $gray-E9;
        border-radius: 15px;
        height: 352px;
        margin-right: 30px;

        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &--icon {
        width: 114px;
        height: 114px;
        margin-bottom: 30px;
    }

    &--boxLable {
        font-size: 20px;
        font-weight: 600;
        display: flex;
        justify-content: center;
    }

    &--boxText {
        width: 80%;
        height: 50px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        color: $calendar-header-black !important;
    }
}

:focus-visible {
    outline: none !important;
}

@media screen and (max-width: 526px) {
    .HomeAboutpage {
        width: 100%;

        &--title {
            font-weight: 800;
            font-size: 24px;
            margin-bottom: 25px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--text {
            font-size: 16px;
            margin-bottom: 62px;
            color: $calendar-header-black !important;
        }

        &--box {
            border: solid 1px $gray-E9;
            width: 100% !important;
            border-radius: 5px;
            height: 352px;
            margin-left: 10px;
            margin-bottom: 10px;
            margin-right: 30px;
        }
    }
}