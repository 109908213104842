@import 'styles/config';

.Advisory {
    &__container {
        display: flex;
        justify-content: center;
        &__Row {
            margin-top: 85px;
            display: grid !important;
            row-gap: 15px !important;
            column-gap: 30px !important;
            grid-template-columns: auto auto auto auto !important;
            @media screen and (max-width: 1240px) {
                grid-template-columns: auto auto !important;
            }
            @media screen and (max-width: 575px) {
                grid-template-columns: auto !important;
            }
        }
    }
}

.AdvisoryCard {
    width: 250px;
    box-shadow: none !important;
    &__img {
        height: 250px;
        border-radius: 7px !important;
        object-fit: cover;
    }
    &__body {
        display: flex;
        justify-content: center;
        &__name {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            @media screen and (max-width: 575px) {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}
