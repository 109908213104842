@import 'styles/config';

.AppFooterBottom {
    //padding-bottom: $spacer * 1.5;

    &__icon {
        width: 24.73px;
        height: 24.73px;
        margin-right: 10.3px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__terms {
        font-size: 14px;
        line-height: 20px;

        @include media-breakpoint-up(sm) {
            text-align: center;
        }

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        @include media-breakpoint-up(sm) {
            font-size: 16px;
            line-height: 29px;
        }

        &--copyright {
            margin-right: 29px;
            text-align: left;

            @media screen and (max-width: 576px) {
                margin-right: 0px;
                padding: 7px 0px;
            }

            @include media-breakpoint-up(sm) {
                text-align: center;
            }
        }
    }
}
