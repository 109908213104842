@import 'styles/config';

.ContactItem {
    margin-bottom: 1.8rem;
    display: flex;

    @media screen and (max-width: 576px) {
        align-items: center;
    }

    @include media-breakpoint-up(sm) {
        margin-bottom: 2.2rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__title {
        font-family: $font-family-sub-headings;
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            font-size: 14px;
        }
    }

    &__details-col {
        margin-left: 0.8rem;
    }

    &__contact {
        font-size: 14px;

        @include media-breakpoint-up(sm) {
            font-size: 16px;
        }
    }
}
