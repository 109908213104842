@import 'styles/config';

$LoadingPage-delay: 300ms;

.LoadingPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $white;
  display: flex;
  align-items: center;

  &__content {
    width: 100%;
    display: block;
    text-align: center;
    z-index: 1000;
    color: $gray-600;
    font-size: 1.125rem;
  }

  &-enter {
    opacity: 1;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity $LoadingPage-delay;
  }

  &-enter-done {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity $LoadingPage-delay;
    pointer-events: none;
  }

  &__image {
    height: 140px;
  }
}
