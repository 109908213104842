@import 'styles/config';

.ResourcesPage {
  &__title {
    text-align: center;
    margin-top: 6rem;
    margin-left: 7rem;
    margin-bottom: 2rem;
  }

  &__breadcrumb {
    text-align: center;
  }

  &__breadcrumb ol li {
    list-style: none;
    display: inline;
    margin: 2rem;
  }

  &__breadcrumb a {
    color: black;
  }

  &__search {
    margin: $spacer * 5 0;
  }

  &__btn {
    border-radius: 1.375rem;
  }
}

.Posts-list__item {
  margin: 1rem;
  width: 17rem !important;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 2rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.PostsHead {
  font-size: 50px;
  font-weight: bold;
  margin-top: 90px;
  margin-bottom: 40px;
}

.PostPage {
  margin-left: 138px;
  margin-right: 138px;
}
