@import 'styles/config';

.EmptyTable {
  padding: $spacer * 4 0;
  text-align: center;
  font-size: 20px;
  color: $text-muted;

  @media screen and (max-width:526px) {
    font-size: 16px;
  }
}
