@import 'styles/config';

.HomeWorkPage {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    &--title {
        font-size: 50px;
        font-weight: 800;
        margin-bottom: 65px;

        @media screen and (max-width: 1920px) {
            font-size: 40px;
            margin-bottom: 55px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 38px;
            margin-bottom: 45px;
        }
    }

    &--text {
        font-size: 20px;
        text-align: justify;
        color: $calendar-header-black !important;
        margin-bottom: 60px;

        @media screen and (max-width: 1920px) {
            font-size: 18px;
            line-height: 22px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &--cardcontain {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
    }

    &--box {
        border: solid 1px $gray-E9;
        width: 50% !important;
        max-width: 535px;
        border-radius: 15px;
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 25px;
        margin-right: 25px;
        overflow: hidden;
        padding: 30px 0px;

        @media screen and (max-width: 1500px) {
            max-width: 400px;
        }
    }

    &--boxText {
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: $calendar-header-black;

        @media screen and (max-width: 1500px) {
            font-size: 14px;

            p {
                margin-bottom: 12px !important;
            }
        }
    }

    &--boxtitle {
        color: $calendar-black !important;
        font-size: 22px;
        font-weight: 800;
        margin-top: 2px;
    }

    &--icon {
        width: 14px !important;
        height: 19px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 526px) {
    .HomeWorkPage {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--title {
            font-size: 24px !important;
            font-weight: 800;
            margin-bottom: 25px !important;
            margin-top: 50px;
        }

        &--text {
            font-size: 12px !important;
            text-align: justify;
            margin-bottom: 0px;
        }

        &--cardcontain {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            margin-top: 20px;
        }

        &--box {
            border: solid 1px $gray-E9;
            width: 100% !important;
            border-radius: 15px;
            height: 352px;
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 20px;
        }

        &--boxText {
            padding-left: 30px;
            padding-right: 30px;
            display: flex;
            flex-direction: row;
            font-size: 12px;
            color: $calendar-header-black;
        }

        &--boxtitle {
            color: $calendar-black !important;
            font-size: 16px;
            font-weight: 800;
            margin-top: 32px;
        }

        &--icon {
            width: 14px !important;
            height: 19px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2px;
            margin-right: 10px;
        }
    }
}