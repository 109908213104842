@import 'styles/config';

$ProjectMembers-spacer: 0.625rem; // 10px
$ProjectMembers-picture-size: 2.75rem; // 44px
$ProjectMembers-picture-size-xs: 1.75rem;

.ProjectMembers {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: $ProjectMembers-spacer;

    &--count {
        background-color: #e9ecef;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__DropDown {
        position: absolute;
        top: calc(100% + 8px);
        left: -23px;
        z-index: 9;
        padding-inline: 0.625rem;
        width: 220px;
        background-color: #ffffff;
        border-radius: 10px !important;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
        max-height: 300px;
        overflow-y: auto;
    }

    &--inline {
        display: inline-flex;
        flex-shrink: 0;
    }

    &__item {
        margin-left: -$ProjectMembers-spacer;
    }

    &__picture {
        height: $ProjectMembers-picture-size;
        width: $ProjectMembers-picture-size;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid $white;

        &--tooltip {
            &:hover {
                border: 2px solid $calendar-blue;
            }
        }

        &--xs {
            width: $ProjectMembers-picture-size-xs;
            height: $ProjectMembers-picture-size-xs;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}
