@import 'styles/config';

.ProfileCardFee {
  font-size: 1rem;
  font-weight: 600;

  &--hour {
    color: $blue;
  }

  &--day {
    color: $teal;
  }
}

@media screen and (max-width:450px) {

.ProfileCardFee {
  font-size: 10px;
  font-weight: 600;

  &--hour {
    color: $blue;
  }

  &--day {
    color: $teal;
  }
}
}