@import 'styles/config';

.TechProductsPage {
  &__title {
    font-size: 50px;
    margin-top: 60px ;
    margin-bottom: $spacer * 4;
    display: flex;
    justify-content: center;
    align-content: center;
    font-weight: bold;
  }

  &__lead {
    font-size: 1.375rem;
    font-family: "Poppins", sans-serif;
    line-height: 2.5rem;
    text-align: center;
    margin: 100px 0 60px 0;
  }
}
