@import 'styles/config';

.navbarSi {
    background-color: $gray-F9;
}

.ProfilePageDetails {
    margin-bottom: $spacer * 8;
    font-size: 16px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    @media screen and (max-width: 1440px) {
        font-size: 14px;
    }

    @media screen and (max-width: 1050px) {
        margin-bottom: $spacer * 5;
    }

    &__text {
        &--semibold {
            font-family: $font-family-headings;

            &--small {
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 2px;

                &--ls0 {
                    letter-spacing: 0px;
                }

                &--regular-font {
                    font-family: $font-family-sans-serif;
                }
            }

            &--large {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 2px;
            }
        }
    }

    &__flex--space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__fontSize {
        @media screen and (max-width: 1050px) {
            font-size: 13px;
        }
    }

    &__width {
        &--minus80 {
            width: calc(100% - 80px);
        }

        &--px80 {
            width: 80px;
        }
    }

    &__padding {
        &--l12 {
            padding-left: 12px;
        }

        &--b12 {
            padding-bottom: 12px;
        }

        &--b22 {
            padding-bottom: 22px;
        }

        &--t42 {
            padding-top: 42px;
        }
    }

    &__flex {
        &--row {
            display: flex;
            flex-direction: row;
        }
    }

    &__icon {
        &--add-icon {
            cursor: pointer;

            svg {
                height: 34px;
                width: 34px;

                @media screen and (max-width: 1920px) {
                    height: 30px;
                    width: 30px;
                }

                @media screen and (max-width: 1050px) {
                    height: 25px;
                    width: 25px;
                }

                path {
                    fill: $calendar-blue;
                }
            }
        }

        &--medium-edit {
            cursor: pointer;

            svg {
                height: 28px;
                width: 28px;


                @media screen and (max-width: 1920px) {
                    height: 22px;
                    width: 22px;
                }

                @media screen and (max-width: 1050px) {
                    height: 20px;
                    width: 20px;
                }
            }
        }

        &--medium-delete {
            cursor: pointer;

            svg {
                height: 28px;
                width: 23px;

                @media screen and (max-width: 1050px) {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    .nav {
        flex-wrap: nowrap;
        font-size: 1rem;


    }

    .nav-tabs {
        border-bottom: none;
        margin-left: 42px;
    }

    .nav-tabs .nav-item {
        margin-bottom: 0;
        font-size: 22px;
    }

    .nav-tabs .nav-link {
        font-size: 22px;
        font-weight: 500;
        color: gray;
        padding-top: 35px;
        padding-bottom: 20px;
        width: 18rem;
        text-align: center;
        margin-bottom: 0;


        @media screen and (max-width: 1440px) {
            font-size: 18px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 1rem;
        }
    }

    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link.active {
        background-color: $gray-F9;
        border-bottom: 2px solid $calendar-blue;
        color: $calendar-black !important;
        z-index: 5;
        font-weight: 1000;
        border-left: none;
        border-top: none;
        border-right: none;
    }

    .tab-content {
        border-top: solid 1px $calendar-header-black;
        padding: $spacer * 2 $spacer * 3;
        z-index: -1;

        @media screen and (max-width:768px) {
            height: auto;
            max-height: 400px;
            overflow-y: scroll;
        }

        @include media-breakpoint-down(sm) {
            padding: $spacer * 1 $spacer * 1;
        }
    }
}

.experience-links {
    .exp-item {
        margin-bottom: 2rem;

        .exp_title {
            margin: 0;
            font-size: 18px !important;
        }

        .exp_dec {
            margin: 0;
            font-size: 14px !important;
        }
    }
}

.innerExpriences {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width:768px) {
        // grid-template-columns: auto;
        display: block;
    }
}

.innerExprience {
    @media screen and (max-width:768px) {
        // grid-template-columns: auto;
        display: block;
    }
}

.SelectedCases {
    color: $calendar-red;
    font-weight: bold;
}

.HeadExpriences {
    font-weight: bold;
}

.exceptions-image {
    width: 100%;
    max-width: 280px;
    height: 400px;

    @media screen and (max-width: 426px) {
        max-width: 150px;
        height: 300px;
    }
}

.Horizontal-line-hr-dashed {
    background-image: linear-gradient(to right, $gray-E9 0%, $gray-E9 50%, transparent 50%);
    background-size: 17px 1px;
    height: 0.5px;
    background-repeat: repeat-x;
}

@media screen and (max-width: 576px) {
    .ProfilePageDetails {
        font-size: 14px;
    }

    .nav-tabs {
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 426px) {
    .ProfilePageDetails {
        margin-bottom: 64px;
        font-size: 12px;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;

        .nav-tabs {
            border-bottom: none;
            margin-left: 0px !important;
        }

        .nav-tabs .nav-item {
            margin-bottom: 0;
            font-size: 14px;
        }

        .nav-tabs .nav-link {
            font-size: 14px;
            font-weight: 500;
            color: gray;
            padding-top: 14px;
            padding-bottom: 11px;
            width: 18rem;
            text-align: center;
            margin-bottom: 0;

            @include media-breakpoint-down(lg) {
                font-size: 14px;
            }
        }
    }
}


// @media screen and (max-width: 426px) {

//     .nav {
//         flex-wrap: nowrap;
//         font-size: 1rem;
//         overflow-x: auto;

//     }
// }

// .nav {
//     -ms-overflow-style: none;
//     /* Internet Explorer 10+ */
//     scrollbar-width: none;
//     /* Firefox */
// }

// .nav::-webkit-scrollbar {
//     display: none;
//     /* Safari and Chrome */
// }