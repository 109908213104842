

// TODO: merge with dashboard navbar styles
.slide-in {
  animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

// TODO: merge with dashboard navbar styles
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
