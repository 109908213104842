@import 'styles/config';

.ReviewModalForm {
  &__button {
    width: 270px;
    height: 50px;
    align-self: flex-end;
    font-size: 16px !important;
    border-radius: 10px !important;
  }
}
