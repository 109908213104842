@import 'styles/config';

$ProfessionSlider-spacer: 0.625rem;

.ProfessionSlider {
  max-width: 100%;
  width: 600px;
  max-height: 45px;
  overflow-y: hidden;

  &__carousel {
    overflow: hidden;
  }

  &__nav {
    font-size: 1.6875rem;
    text-decoration: none !important;

    &--prev {
      padding-right: $ProfessionSlider-spacer;
    }

    &--next {
      padding-left: $ProfessionSlider-spacer;
    }
  }
}
