.PodcastPlayerModal {
    #default-modal {
        .modal-content {
            background-color: transparent;
            border: transparent;
            box-shadow: none;
        }
    }
    &__body {
        // padding: 0;
        background-color: transparent;
    }
}
