@import 'styles/config';

.pricing-table-wrapper {
    overflow-x: auto;
}

.pricing-table {
    display: grid;
    grid-template-columns: 6fr repeat(3, 1fr);
    text-align: left;
    padding-bottom: 20px;

    .features-header {
        grid-column: 1 / 2;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        padding-left: 20px;
    }

    .plan-header {
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }

    .feature-title {
        font-size: 18px;
        padding-top: 30px;
        padding-bottom: 10px;
        padding-left: 20px;
        font-weight: 600;
        color: #151C2D;

        @media screen and (max-width: 1280px) {
            font-size: 16px;
            padding-top: 30px;
        }

        @media screen and (max-width: 540px) {
            font-size: 16px;
            padding-top: 20px;
        }
    }

    .feature-description {
        grid-column: 1 / 2;
        color: #788B9A;
        font-size: 16px;
        padding-left: 20px;

        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }

    .plan-value {
        text-align: center;
        padding: 20px 0;
    }

    .check {
        height: 24px;
        width: 24px;
    }
}

// Media query for mobile screens with a max-width of 450px
@media screen and (max-width: 450px) {
    .pricing-table {
        grid-template-columns: 6fr repeat(4, minmax(120px, 1fr)); // Ensure columns have minimum width to enable scrolling
        width: 800px; // Set total width wider than the screen to force horizontal scroll
    }
}