@import 'styles/config';

.TechProductsPageSection {
  display: block;
  flex-direction: row;
  padding-top: 2.5rem;
  padding-bottom: 15rem;
  margin: auto;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  &--right {
    flex-direction: row-reverse;
  }

  // column containing the text content
  &__content-col {
    width: 100%;
    padding: 0 10%;

    @include media-breakpoint-up(lg) {
      width: 45%;

      &--left {
        padding-left: 10%;
        padding-right: 6.32%;
      }

      &--right {
        padding-left: 6.32%;
        padding-right: 10%;
      }
    }
  }

  // column containing the image
  &__image-col {
    width: 100%;
    position: relative;

    @include media-breakpoint-up(lg) {
      width: 55%;
    }
  }

  // wraps an icon in a rounded circle with gray background
  &__icon-wrapper {
    height: 114px;
    width: 114px;
    border-radius: 50%;
    background-color: #f9f9f9;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem 1rem;
    margin-bottom: 2rem !important;
  }

  &__title {
    line-height: 2.43rem;
  }

  // the main text
  &__content {
    font-size: 1.125rem;
    line-height: 2rem;
    color: $text-muted;
  }

  // white card that contains an image
  &__image-card {
    position: relative;
    box-shadow: 12px 12px 60px rgb(0 0 0 / 6%);
    border-radius: 16px;
    margin-top: 3rem;
    margin-left: 10%;
    margin-right: 10%;

    @include media-breakpoint-up(lg) {
      width: 85%;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;

      &--left {
        margin-right: auto;
      }

      &--right {
        margin-left: auto;
      }
    }
  }

  // main image image a white card
  &__image {
    width: 100%;
    height: 100%;
  }

  // the gray shadow sitting below the image
  &__image-shadow {
    top: 33%;
    width: 80%;
    height: 85%;
    position: absolute;
    background-color: #f9f9f9;
    z-index: -1;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;

      &--right {
        left: 0;
      }

      &--left {
        right: 0;
      }
    }
  }
}
