@import 'styles/config';

$CardCarouselLoading__spacer: $spacer * 10;

.CardCarousel {
  display: block;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__empty {
    font-size: 1.25rem;
    text-align: center;
    padding-bottom: $CardCarouselLoading__spacer;
    padding-top: $CardCarouselLoading__spacer;
  }
}

.mobileCarousel {
  display: none !important;
}

@media screen and (max-width: 500px) {
  .btnDisplayNone {
    display: none;
  }

  .mobileCarousel {
    display: flex !important;
    flex-direction: row;
    margin: 0;
    justify-content: center;
    margin-top: 15px;
    // margin-bottom: 15px;
  }
}


