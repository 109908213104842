@import 'styles/config';

.ProfilePageSkills {
    margin-bottom: 40px;
    margin-left: 5%;
    margin-right: 5%;

    @include media-breakpoint-up(sm) {
        margin-bottom: $spacer * 5;
        margin-left: 0;
        margin-right: 0;
    }

    &__container {
        &--input {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &--left {
            width: calc(50% - 8px);

            @media screen and (max-width: 1024px) {
                width: 100%;
            }
        }

        &--right {
            width: 16px;
        }

        &--center {
            width: calc(50% - 8px);
            padding: 0 8px;
            text-align: center;

            @media screen and (max-width: 1024px) {
                width: calc(100% - 16px);
                text-align: left;
                margin-top: 16px;
            }
        }
    }

    &__row {
        display: grid;
        grid-column-gap: 0;
        grid-template-columns: 1fr;

        @include media-breakpoint-up(sm) {
            grid-template-columns: 2fr 2fr;
        }
    }
}

.ProfilePageSkillsItem {
    margin-bottom: 34px;
    align-items: center;

    &__name {
        // font-size: 13px;
        // letter-spacing: -0.1px;
        // text-align: right;

        // @include media-breakpoint-up(sm) {
        //     text-align: left;
        //     letter-spacing: unset;
        //     font-size: 18px;
        // }
        @media screen and (max-width: 1050px) {
            font-size: 14px;
        }

        @media screen and (max-width: 426px) {
            font-size: 13px !important;
        }

        @media screen and (max-width: 576px) {
            font-size: 16px;
        }

        // @include media-breakpoint-up(md) {
        //     font-size: 22px;
        // }
    }

    &__star {
        display: inline-block;
        background-color: #0990f2;
        color: $primary;
        border-radius: 10rem;
        width: 20px;
        height: 5px;
        margin-right: 0.312rem !important;

        @include media-breakpoint-up(sm) {
            margin-right: $spacer * 0.3;
            width: 30px;
            height: 8px;
        }

        @include media-breakpoint-up(md) {
            margin-right: $spacer * 0.5;
            width: 50px;
            height: 10px;
        }

        &:last-child {
            margin-right: 0;
        }

        &--gray {
            background-color: #e9e9e9;
        }
    }
}

.disableAdd {
    cursor: not-allowed;

    svg {
        g {
            g {
                path {
                    fill: $gray-C6;
                }
            }
        }
    }
}