@import '../../../../styles/config';

.MatterSearchControl {

  &__suggestion {
    &--active.dropdown-item {
      background-color: $dropdown-link-active-bg;
      color: $dropdown-link-active-color;

      .text-muted {
        color: $dropdown-link-active-color !important;
      }
    }
  }

  &__suggestion-title {
    line-height: 1.25;
  }

  &__suggestion-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // disable focus/hover/active states for the empty-results item
  &__empty-dropdown-item.dropdown-item {
    pointer-events: none;

    &.active,
    &:hover,
    &:active,
    &:focus {
      background: $white;
      color: initial;
    }
  }
}
