@import 'styles/config';

.HelpCenterArticleHeader {
  position: relative;
  margin-bottom: 3.75rem;

  &--Header {
    font-size: 40px;
    font-family: $font-family-headings;
    line-height: 60px;
  }

  &--content {
    font-size: 18px;
    color: $calendar-header-black;
    line-height: 40px;
    font-family: $font-family-sans-serif;
    text-align: justify;
  }

  &__div-update {
    color: $calendar-header-black;

    @media screen and (max-width: 800px) {
      position: relative;
    }
  }

  &__headingBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.125rem;
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  &--profileName {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
  }

  &--profileText {
    font-size: 14px;
    color: $calendar-light-green;
    font-weight: 700;
    padding-left: 5px;
  }
}
