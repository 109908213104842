@import '../../../../styles/config';

.MonthSelectControl {
  // position: absolute;
  // right: 50px;

  &__arrow-button {
    cursor: pointer;

    &--disabled {
      cursor: default;
    }
  }
}
