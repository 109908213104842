@import 'styles/config';

.HomePage {
  position: relative;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 79px);
    overflow-y: auto;
  }

  &__section {
    margin-bottom: $spacer * 13;
  }

  &__heading {
    font-size: 2.625rem;
    margin-bottom: $spacer * 4;
    text-align: center;
  }

  &__HowItWorks {
    margin-bottom: 13rem;
  }

  &__section {
    height: 100%;
    margin-top: $spacer * 0.6;
    margin-bottom: $spacer * 2;
    // transition: height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    // overflow: hidden
  }

  &__rightfullogo {
    height: 22px;
  }
}

.ProfilesPage {
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

// .animate__fadeOutUp {
//   height: 0;
//   /* Shrink to 0 */
//   opacity: 0;
//   transform: translateY(-50px);
// }

// .animate__fadeInUp {
//   height: 100%;
//   opacity: 1;
//   transform: translateY(0);
// }

// .animate__fadeOutDown {
//   height: 0;
//   opacity: 0;
//   transform: translateY(50px);
// }

// .animate__fadeInDown {
//   height: 100%;
//   /* Expand back to full height */
//   opacity: 1;
//   transform: translateY(0);
// }

.InteractiveLogo {

  & img {
    max-width: 541px;

    @media screen and (max-width: 1920px) {
      max-width: 450px;
    }

    @media screen and (max-width: 1440px) {
      max-width: 350px;
    }

    @media screen and (max-width: 768px) {
      max-width: 250px;
    }

    @include media-breakpoint-down(md) {
      max-width: 400px;

      @media screen and (max-width: 526px) {
        max-width: 295px;
      }
    }
  }
}


.HomePageHeader {
  position: relative;
  transition: all 250ms ease-in-out;
  // transition: opacity 0.3s ease, height 0.3s ease, transform 0.3s ease;

  .InteractiveLogo {
    transition: all 100ms ease;
  }

  &__title-button {
    overflow: hidden;
    transition: all 150ms ease-in-out;
  }

  &__removeElement {
    display: none;
    opacity: 0;
    height: 0%;
  }
}

.homepage-scrolltobottom-btn {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
  width: 100%;
  height: 80px;
  display: block;

  @media (max-height: 767px) {
    height: 50px;
  }

  svg {
    width: 40px;
    height: 40px;
    animation: bounce 2s infinite;
  }
}

@keyframes bounce {

  0%,
  20%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px); // Adjust the bounce height
  }

  60% {
    transform: translateY(10px); // Adjust the bounce height
  }
}