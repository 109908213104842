@import '../../../../styles/config';

.HelpCenterArticleSection {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;

  &--heading {
    font-size: 20px;
    font-family: $font-family-headings;
    line-height: 30px;
  }

  &--content {
    font-size: 16px;
    font-family: $font-family-sans-serif;
    color: $calendar-header-black;
    padding-top: 14px;
  }

  &--image {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;

    img {
      max-width: 70%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
