@import 'styles/config';

.CarouselNav {
    &__side-item {
        flex: 1;
        margin-bottom: 10px;
    }
}
.default-nav-class {
    margin: auto;
}
.CarouselNavItem {
    margin: 0.5rem;
}

.CarouselNavLink {
    color: #788b9a;
    border: #788b9a solid 1px;
}


@media screen and (max-width: 426px) {

.mobileViewAll{
    font-size: 10px;
}
}