@import 'styles/config';

.ProfilePageResources {
  margin-bottom: $spacer * 4;
  text-align: center;
  margin-left: $spacer * 2;
  margin-right: $spacer * 2;
  position: relative;

  &__carousel {
    overflow: hidden;
  }

  &__addbtn {
    position: absolute;
    top: 0px;
    right: 15px;
    height: 26px;
    width: 26px;
  }
}

.ProfilePageResources__title {
  position: relative;
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 0;
}

.ProfilePageResources__lead {
  font-size: 24px;
  margin-bottom: 68px;
  color: #788b9a;
}

.badgeFiltermobile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;

}

.ProfilePageResources__title-button {
  position: absolute;
  font-family: $font-family-sans-serif !important;
  // text-transform: uppercase;
  font-size: 16px !important;
  margin-top: 10px;
  font-weight: 100 !important;
  line-height: 19px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  background: transparent !important;
  box-shadow: none;
  color: $calendar-blue !important;
  border: solid 1px $calendar-blue;
  right: 0;
}


@media screen and (max-width: 450px) {

  .ProfilePageResources__title {
    position: relative;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 0;
  }

  .ProfilePageResources__lead {
    font-size: 12px;
    margin-bottom: 25px;
    color: #788b9a;
  }

  .badgeFiltermobile {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;

    .slick-slider {
      width: calc(100vw - 90px);
      padding-right: 10px;
    }
  }


}