@import 'styles/config';

.HomePageJobs {
    margin-top: 100px;
    padding-bottom: 60px;

    @media screen and (max-width: 1920px) {
        margin-top: 0px !important;
    }

    &__header {
        padding-top: 130px;
        display: flex;
        justify-content: center;
    }

    &__title {
        max-width: 800px;
        text-align: center;
        font-size: 50px;
        margin-bottom: 65px;

        @media screen and (max-width: 1920px) {
            font-size: 40px;
            margin-bottom: 55px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 38px;
            margin-bottom: 45px;
        }
    }

    &__description {
        font-size: 20px;
        margin-bottom: 62px;
        text-align: justify;
        color: $gray-550;

        @media screen and (max-width: 1920px) {
            font-size: 18px;
            line-height: 22px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__button.btn {
        padding: 1.375rem 2.5rem;
        border-radius: 0.8125rem;
        line-height: 19px !important;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}