$btn-border-radius-xl: 0.8125rem;

.btn-lg {
  border-radius: $border-radius;
}

.small_padding {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.btn-border-radius-xl {
  border-radius: $btn-border-radius-xl !important;
}

.btn-md {
  @include button-size(0.8125rem,
    0.8125rem,
    $input-btn-font-size,
    $input-btn-line-height,
    $btn-border-radius,
  );
}

.btn-xl {
  @include button-size(0.9375rem,
    0.9375rem,
    $input-btn-font-size,
    $input-btn-line-height,
    $btn-border-radius);
}

.btn-xxl-wide {
  @include button-size(0.9375rem * 1.25,
    0.9375rem * 4.75,
    $input-btn-font-size,
    $input-btn-line-height,
    $btn-border-radius-xl,
  );
}

.btn-xl-wide {
  @include button-size(0.9375rem,
    0.9375rem * 4,
    $input-btn-font-size,
    $input-btn-line-height,
    $btn-border-radius-xl,
  );
  // TODO: make these fonts consistent in variations
  //font-family: $font-family-sans-serif !important;
  //font-weight: bold;
}

.btn-featured-inverted {
  @include button-size($btn-padding-y,
    $btn-padding-x,
    $input-btn-font-size,
    $input-btn-line-height,
    $btn-border-radius,
  );

  color: #0990f2;
  background: white;
  border: solid;
  border-width: 0.5px;
  border-color: #0990f2;
  font-family: $font-family-headings;

  background-clip: padding-box; // fixes linear-gradient bleeding with border radius

  @include hover() {
    color: #0990f2;
  }

  &:focus,
  &.focus,
  &:active {
    color: #0990f2;
    box-shadow: none !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: #0990f2;

  }

  &--no-shadow {
    box-shadow: none;
  }

  &--no-border-radius {
    border-radius: 0;
  }
}

.btn-featured-primary {
  @include button-size($btn-padding-y,
    $btn-padding-x,
    $input-btn-font-size,
    $input-btn-line-height,
    $btn-border-radius,
  );

  color: white;
  background: $calendar-blue;
  border: solid;
  border-width: 0.5px;
  border-color: $calendar-blue;

  font-family: $font-family-headings;
  background-clip: padding-box; // fixes linear-gradient bleeding with border radius

  @include hover() {
    color: white;
    background: $calendar-blue;
    border-color: $calendar-blue;
  }

  &:focus,
  &.focus,
  &:active {
    color: white;
    background: $calendar-blue !important;
    border-color: $calendar-blue !important;
    box-shadow: none !important;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--no-border-radius {
    border-radius: 0;
  }
}

.btn-featured {
  //Shaded
  border: hidden;
  color: $white;

  font-family: $font-family-headings;

  background: $btn-featured-bg;
  background-clip: padding-box; // fixes linear-gradient bleeding with border radius

  @include hover() {
    color: white;
    background: $btn-featured-bg-hover;
  }

  &:focus,
  &.focus,
  &:active {
    color: $white;
    box-shadow: none !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
    background-color: $btn-featured-bg-hover;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--no-border-radius {
    border-radius: 0;
  }
}

.btn--bold {
  font-family: $font-family-headings;
}

.btn-gray {
  @include button-variant($gray-550, $gray-550);
}
