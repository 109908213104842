@import 'styles/config';

$leftColor: #f9c7c1;
$rightColor: #b5d4f3;

.CaseEstimator__title {
	display: flex;
	justify-content: center;
}

.CaseEstimator__title h4 {
	max-width: 600px;
	margin: 30px 0 40px;
	font-weight: bolder;
	color: #313131;
	text-align: center !important;
}

.CaseEstimator-preview__container {
	width: 100%;
}

.CaseEstimator__subtitles {
	display: flex;
	justify-content: center;
	font-weight: bolder;
}

.CaseEstimator__subtitles span {
	max-width: 200px;
	text-align: center;

	@media screen and (min-width: 768px) {
		margin: 0 40px;
	}

	@media screen and (min-width: 1024px) {
		margin: 0 40px;
	}

	@media screen and (min-width: 1440px) {
		margin: 0 40px;
	}

	@media screen and (min-width: 2560px) {
		margin: 0 70px;
	}
}

.CaseEstimator-preview__content {
	display: inline-block;
	width: 100%;
  	justify-content: right;
  	align-items: right;

	.CaseEstimator-preview__titleText {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		font-size: 10px;
		color: white;
		margin-top: -50px;
	}

	.CaseEstimator-preview__titleText p {
		font-weight: bolder;
		margin: 0;
		height: 50px;
		width: 50px;
	}

	.CaseEstimator-preview__title {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
	}

	.CaseEstimator-preview__rightTitle {
		height: 55px;
		width: 55px;
		background-color: $rightColor;
		border-bottom-right-radius: 50px;
		border-top-right-radius: 50px;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	.CaseEstimator-preview__leftTitle {
		height: 55px;
		width: 55px;
		background-color: $leftColor;
		border-bottom-left-radius: 50px;
		border-top-left-radius: 50px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}
}

.CaseEstimator-preview__cost {
	padding: 3px 12px;
	border: 1px solid black;
	border-radius: 8px;
	font-size: 0.8rem;
}

.CaseEstimator-preview__table {
	display: table;
	margin: 0;
	width: 100%;
	.CaseEstimator-preview__row {
		display: table-row;

		.CaseEstimator-preview__cell {
			display: table-cell;
			vertical-align: top;
			width: 500px;
			max-width: 500px;

			&.bar {
				width: 20px;
				background-color: #B8B8B8;
			}
		}
	}
}

.text {
	padding: 10px;
	font-size: 12px;
	width: 300px;

	@media screen and (min-width: 1024px) {
		width: 21vw;
	}

	@media screen and (min-width: 1440px) {
		width: 15vw;
	}

	@media screen and (min-width: 2560px) {
		width: 15vw;
	}
}

.activity > div {
	display: table-cell;
}

.activity.client {
	text-align: right;
	direction: rtl;

	.arrow-ball {
		background-color: $leftColor;
	}
}

.activity.barrister {
	.arrow-ball {
		background-color: $rightColor;
	}
}

.empty-activity {
	height: 30px;
}

.arrow-main {
	.arrow-head {
		content: "";
  		display: table-cell !important;
  		width: 0;
  		height: 0;
  		border-top: 5px solid transparent;
  		border-bottom: 5px solid transparent;
  		vertical-align: middle;
	}

	.arrow-tail {
		width: 40px;
  		height: 1px;
  		background: #C8A962;
  		content: "";
  		display: inline-block;
	}

	&.barrister {
		.arrow-head {
			border-right: 5px solid #C8A962;
		}
	}

	&.client {
		.arrow-head {
			border-left: 5px solid #C8A962;
		}
	}

	.arrow-ball {
		border-radius: 50%;
		height: 15px;
		width: 15px;
		display: table-cell;
		vertical-align: middle;
	}
}
