@import 'styles/config';

$NavbarNotifications__badge-offset: 0.625rem;
$NavbarNotifications__dropdown-menu-width: 21.875rem;
$NavbarNotifications__dropdown-menu-max-width: 395px;
$NavbarNotifications-dropdown-menu-border-radius: 0.6875rem;

.NavbarNotifications {
  border: none !important;

  &__icon-wrapper {
    position: relative;
  }

  &__icon {
    width: 20px;
    height: 24px;
  }

  &__badge {
    position: absolute;
    top: -$NavbarNotifications__badge-offset;
    right: -$NavbarNotifications__badge-offset;
    width: 8px;
    height: 8px;
    padding: 0 !important;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 50% !important;
    border: 1px solid #e9ecef;
  }

  &__dropdown-menu {
    max-width: $NavbarNotifications__dropdown-menu-max-width !important;
    width: $NavbarNotifications__dropdown-menu-width;
    padding-top: $NavbarNotifications-dropdown-menu-border-radius !important;
    padding-bottom: $NavbarNotifications-dropdown-menu-border-radius !important;
    border-radius: $NavbarNotifications-dropdown-menu-border-radius !important;
    border: none !important;

    // little arrow/triangle at the top of the dropdown menu when open
    &::after {
      border-bottom: 6px solid $white;
      border-left: 6px solid rgba(0, 0, 0, 0);
      border-right: 6px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      right: 1.25rem;
      position: absolute;
      top: -5px;
    }
  }

  &__empty {
    text-align: center;
    padding: $spacer 0;
    color: $text-muted;
  }
}

