@import 'styles/config';

.CardCarouselNav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;

    &__icon_button {
        align-items: center;
        display: flex;
        justify-content: center;
        transition: all 0.1s ease-in-out;
        height: 46px;
        width: 46px;
        border-radius: 50%;
        border: 4px solid $calendar-header-black;

        &:hover {
            // transform: scale(1.2);
            // .padding_left_3 {
            //     padding-left: 0;
            // }
            // .padding_right_3 {
            //     padding-right: 0;
            // }
        }

        &:active {
            transform: scale(1);
        }

        .padding_left_3 {
            padding-left: 3.5px;
            margin-right: 1.7px;
        }

        .padding_right_3 {
            padding-right: 3.5px;
            margin-left: 1.7px;
        }

        .svg_fontAwesomeIcon {
            height: 25px;
            width: 15px;

            // font-size: 24px;
            path {
                fill: $calendar-header-black;
            }
        }

        // @include media-breakpoint-down(lg) {
        //     padding: 0.4rem;
        // }

        @media screen and (max-width: 1440px) {
            height: 36px;
            width: 36px;
        }
    }

    &__button {
        align-items: center;
        display: flex;
        padding: 0 $spacer * 1;
        transition: all 0.1s ease-in-out;
        height: 5.9375rem;

        &:hover {
            transform: scale(1.2);
        }

        &:active {
            transform: scale(1);
        }

        @include media-breakpoint-down(lg) {
            padding: 0.4rem;
        }
    }

    &__button-image {
        height: 5.9375rem;

        @include media-breakpoint-down(lg) {
            height: 3rem;
        }
    }
}

.margin_left_10 {
    margin-left: 44px;
}

.margin_right_10 {
    margin-right: 44px;
}

@media screen and (max-width: 1000px) {
    .margin_left_10 {
        margin-left: 32px;
    }

    .margin_right_10 {
        margin-right: 32px;
    }
}

// @media screen and (max-width: 768px) {
//     .margin_left_10 {
//         margin-left: 36px;
//     }
//     .margin_right_10 {
//         margin-right: 36px;
//     }
// }

@media screen and (max-width: 576px) {
    .margin_left_10 {
        margin-left: 22px;
    }

    .margin_right_10 {
        margin-right: 22px;
    }
}