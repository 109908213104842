.PlanCardContainer {
    padding: 4px;
    border-radius: 33px;
    flex: 0 0 25%;

    @media screen and (max-width: 1440px) {
        flex: 0 0 33.33%;
    }

    @media screen and (max-width: 991px) {
        flex: 0 0 50%;
    }

    @media screen and (max-width: 767px) {
        flex: 0 0 70%;
    }

    @media screen and (max-width: 375px) {
        flex: 0 0 100%;
    }

    &__MostPopular {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 767px) {
            height: 40px;
        }

        p {
            color: white;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;

            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }
    }

    &__PlanCard {
        padding: 20px 20px 30px;
        border-radius: 30px;

        &__header {
            display: flex;
            align-items: center;
            column-gap: 20px;

            @media screen and (max-width: 1240px) {
                column-gap: 16px;
            }

            @media screen and (max-width: 767px) {
                column-gap: 10px;
            }

            img {
                width: 73px;
                height: 73px;

                @media screen and (max-width: 1240px) {
                    width: 60px;
                    height: 60px;
                }

                @media screen and (max-width: 767px) {
                    width: 50px;
                    height: 50px;
                }
            }

            p {
                font-weight: 600;
                font-size: 25px;
                text-transform: uppercase;

                @media screen and (max-width: 1240px) {
                    font-size: 22px;
                }
            }
        }

        &__heading {
            margin: 20px 0 10px;

            @media screen and (max-width: 767px) {
                margin: 10px 0 10px;
            }

            p {
                font-weight: 500;
                font-size: 18px;

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }

        &__pricing {
            display: flex;
            align-items: end;
            color: #151C2D;
            font-weight: 600;

            &--currencysign {
                font-size: 24px;
                padding-bottom: 20px;

                @media screen and (max-width: 1440px) {
                    font-size: 22px;
                    padding-bottom: 16px;
                }
            }

            &--price {
                margin-right: 10px;
                font-size: 60px;

                @media screen and (max-width: 1440px) {
                    font-size: 54px;
                }

                @media screen and (max-width: 1240px) {
                    font-size: 48px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 42px;
                }
            }

            &--time {
                font-size: 20px;
                color: #788B9A;
                padding-bottom: 20px;

                @media screen and (max-width: 1440px) {
                    font-size: 18px;
                    padding-bottom: 16px;
                }
            }
        }

        &__getstartedbtn {
            margin: 10px 0 20px;

            .btn {
                border-radius: 10px;
                padding: 12px 16px;
                font-weight: 500;
                font-size: 16px;
                box-shadow: none;

                @media screen and (max-width: 1440px) {
                    font-size: 14px;
                }
            }
        }

        &__perkslist {
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 10px;


            &__perk {
                display: flex;
                gap: 10px;

                &--tickicon {
                    min-width: 15px;
                    height: 15px;
                    margin-top: 4px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        max-width: 10px;
                    }
                }

                &--pertext {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}
