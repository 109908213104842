@import 'styles/config';

.CoolLegalLinks {
  &__paragraph {
    margin-top: 90px;
    margin-bottom: 80px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1000px) {
      margin-top: 70px;
      margin-bottom: 60px;
    }
  }

  &__button {
    width: 150px !important;
    margin-top: 20px;
  }

  &__button-col {
    width: 10%;
    padding-right: 30px;

    @media screen and (max-width: 500px) {
      padding-right: 0;
    }
  }

  &__title-col {
    font-weight: 800;
    width: 10%;
  }

  &__description-col {
    width: 80%;
  }

  &__table {
    &.table {
      width: 100%;
      font-size: 18px;
      margin-bottom: 142px !important;
      border-spacing: 0 !important;

      th {
        background: $white !important;
        border: none !important;
        font-size: 18px !important;
        color: #788B9A !important;
        font-weight: 400 !important;
        padding: 12px 12px 25px 30px !important;

        @media screen and (max-width: 1000px) {
          padding-left: 20px;
        }

        @media screen and (max-width: 500px) {
          padding-left: 0;
        }
      }

      tbody tr {
        height: 150px;

        @media screen and (max-width: 1000px) {
          height: 100px;
        }

        &:nth-child(odd) {
          background-color: #F9F9F9;
        }
      }

      td {
        padding-left: 30px !important;
        padding-top: 30px !important;
        padding-bottom: 30px !important;
        border: none !important;
        font-size: 16px !important;
        background-color: unset !important;
        vertical-align: top !important;

        @media screen and (max-width: 1000px) {
          padding-left: 20px !important;
          padding-top: 20px !important;
          padding-bottom: 20px !important;
          border: none !important;
          font-size: 12px !important;
        }

        @media screen and (max-width: 500px) {
          padding: 0 !important;
          font-size: 10px !important;
        }
      }
    }
  }

  &__page-title {
    font-size: 40px;
    font-weight: 800;

    @media screen and (max-width: 1000px) {
      font-size: 30px;
      font-weight: 600;
    }
  }

  &__page-description {
    font-size: 20px;
    color: #788B9A;
    padding-bottom: 80px;
    border-bottom: 1px solid #E9E9E9;
    margin: 0;

    @media screen and (max-width: 1000px) {
      font-size: 16px;
      padding-bottom: 50px;
    }
  }
}
