.content-page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 82.5px 0 48.5px 0;

  @media screen and (max-width: 768px) {
    margin: 75px 0 50px 0;
  }
}

.content-page-title {
  font-size: 50px;
  font-family: $font-family-headings;
  color: $calendar-black;
  line-height: 76px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    line-height: 65px;
    font-size: 40px;
  }

  @media screen and (max-width: 768px) {
    line-height: 55px;
    font-size: 38px;
  }

  @media screen and (max-width: 425px) {
    line-height: 40px;
    font-size: 24px;
  }

  &--subtle {
    margin-bottom: 0;

    @media screen and (max-width: 1024px) {
      font-size: 45px;
    }

    @media screen and (max-width: 768px) {
      font-size: 40px;
    }

    @media screen and (max-width: 425px) {
      font-size: 24px;
    }
  }
}
