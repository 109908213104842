@import '../../../../styles/config';

.HeaderSubCategory {
  margin-bottom: 5rem;

  &--Header {
    font-size: 2.5rem;
    line-height: 3.75rem;
    font-family: $font-family-headings;
    margin-bottom: 1rem;
  }

  &--content {
    font-size: 1.125rem;
    color: $calendar-header-black;
    line-height: 2.5rem;
    text-align: justify;
    font-family: $font-family-sans-serif;
  }

  &--img {
    margin-bottom: 2rem;

    &--div {
      background: $gray-E9;
      height: 7.38rem;
      width: 7.38rem;
    }
  }
}
