@import 'styles/config';

.GuideToChoosingPage {
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 82.5px 0 48.5px 0;
  }

  &__title {
    color: #151c2d;
    font-family: $font-family-headings;
    text-align: center;
    font-size: 50px;
    line-height: 82px;

    @media screen and(max-width: 400px) {
      color: #151c2d;
      font-weight: 600;
      font-family: $font-family-sans-serif;
      text-align: center;
      width: 100%;
      font-size: 1.5rem;
      line-height: 3rem;
    }
  }

  &__paragraph {
    font-size: 18px;
    line-height: 40px;
    color: $calendar-black;
    font-family: $font-family-sans-serif;
    text-align: justify;
  }
}
