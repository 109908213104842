@import 'styles/config';

.CommonPillSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 26px;
  position: relative;

  &__chevron {
    margin-left: auto;

    path {
      fill: $calendar-header-black;
    }
  }

  &__label {
    font-size: 14px;
    font-family: $font-family-sub-headings;
    line-height: 19px;
    color: $calendar-header-black;
    margin-right: 25px;
  }

  &__control {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $gray-E9;
    padding: 9px 15px;
    border-radius: 28px;
    min-width: 135px;
    height: 36px;
    justify-content: space-between;
    font-size: 14px;
    font-family: $font-family-sub-headings;
    line-height: 19px;
    color: $calendar-black;
  }

  &__item {
    line-height: 33px;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    color: $calendar-black;
    font-family: $font-family-sub-headings;

    &:hover {
      color: $calendar-blue;
    }

    &--active {
      color: $calendar-blue;
    }
  }

  &__tooltip {
    opacity: 1 !important;
    // transform: translate(271px, 480px) !important;

    & > .tooltip-inner {
      background-color: $white;
      color: #000;
      border: 1px solid $gray-E9;
      border-radius: 15px;
      min-width: 135px;
      max-width: 400px;
      max-height: 300px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & > .tooltip-arrow {
      border-top: 5px solid #062e56;
      border-bottom-color: #000;
    }

    &.bs-tooltip-bottom .arrow::before,
    &.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
      border-bottom-color: #e9e9e9;
      border-width: 0 0.3em 0.3rem;
    }

    .arrow {
      &::before {
        transform: translate(-42px, 0px) !important;
        border-bottom-color: #e9e9e9;
        border-width: 0 0.3em 0.3rem;
      }
    }
  }

  &__dropdown-menu {
    padding: 14px;

    &--one-item {
      padding: 3px;
    }
  }
}

@media screen and (max-width: 426px) {
  .CommonPillSelect {
    display: flex;
    flex-direction: column;
    align-items: initial;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;

    &__chevron {
      margin-left: auto;

      path {
        fill: $calendar-header-black;
      }
    }

    &__label {
      font-size: 14px;
      font-family: $font-family-sub-headings;
      line-height: 19px;
      color: #788b9a;
      margin-right: 25px;
      padding-bottom: 12px;
    }

    &__control {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid $gray-E9;
      padding: 9px 15px;
      border-radius: 28px;
      min-width: 135px;
      height: 36px;
      justify-content: space-between;
      font-size: 14px;
      font-family: $font-family-sub-headings;
      line-height: 19px;
      color: $calendar-black;
    }

    &__item {
      line-height: 33px;
      font-size: 14px;
      text-align: left;
      cursor: pointer;
      color: $calendar-black;
      font-family: $font-family-sub-headings;

      &:hover {
        color: $calendar-blue;
      }

      &--active {
        color: $calendar-blue;
      }
    }

    &__tooltip {
      opacity: 1 !important;
      // transform: translate(271px, 480px) !important;

      & > .tooltip-inner {
        background-color: $white;
        color: #000;
        border: 1px solid $gray-E9;
        border-radius: 15px;
        min-width: 135px;
      }

      & > .tooltip-arrow {
        border-top: 5px solid #062e56;
        border-bottom-color: #000;
      }

      &.bs-tooltip-bottom .arrow::before,
      &.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
        border-bottom-color: #e9e9e9;
        border-width: 0 0.3em 0.3rem;
      }

      .arrow {
        &::before {
          transform: translate(-42px, 0px) !important;
          border-bottom-color: #e9e9e9;
          border-width: 0 0.3em 0.3rem;
        }
      }
    }

    &__dropdown-menu {
      padding: 14px;

      &--one-item {
        padding: 3px;
      }
    }
  }
}
