@import 'styles/config';

.HomePageHeader {
    text-align: center;
    padding-top: $spacer * 0.6;
    margin-bottom: $spacer * 0 !important;

    &__title {
        font-size: 80px;
        font-family: $font-family-headings;
        line-height: 96px;
        color: $calendar-black;
        margin-bottom: 35px;
        padding: 0px 20px;

        @media screen and (max-width: 1920px) {
            font-size: 60px;
            line-height: 72px;
            margin-bottom: 24px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 55px;
            line-height: 68px;
            margin-bottom: 22px;
        }

        @media screen and (max-width: 1240px) {
            font-size: 50px;
            line-height: 64px;
            margin-bottom: 20px;
        }

        @media screen and (max-width: 768px) {
            font-size: 40px;
            line-height: 65px;
            margin-bottom: 18px;
        }
    }

    &__button.btn {
        @include button-size($btn-padding-y * 2.75, $btn-padding-x * 1.25, 1.25rem, 1.5, 0.5rem);
        font-family: $font-family-headings !important;
        font-size: 16px !important;
        line-height: 19px !important;
        width: 280px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 15px 15px 120px;
        // margin: 15px 15px $spacer * 10;

        @media screen and (max-width: 526px) {
            @include button-size($btn-padding-y * 2, $btn-padding-x * 1.25, 1.25rem, 1.5, 0.5rem);
            width: 100% !important;
            margin: 0 0 20px;
        }

        &.btn-outline-primary {
            background: $white !important;
            color: $primary !important;
        }
    }
}

.homepage--btn {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
}

.homepage-auth--button {
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    padding-bottom: 10px;
}

.homepage-auth__signin {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-weight: 500;
    background: linear-gradient(to left, #01c8ee 0%, #0a8bf2 100%);
    transition: background-color 250ms ease, border-radius 250ms ease;
    border: none;
    outline: none;

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }
}

.homepage-auth__signuplink {
    font-size: 1rem;
    font-weight: 600;
    color: $skyblue;
    line-height: 24px;
    z-index: 999;
}

.HomePageHeader__lead {
    color: $calendar-blue;
    font-family: $font-family-sans-serif;
    font-size: 37px;
    line-height: 44px;
    margin-bottom: $spacer * 3.3;
}

.HomePageHeader__text {
    color: $calendar-blue;
    font-size: 37px;
    font-family: $font-family-sans-serif;
    margin-bottom: 42px;
    line-height: 29px;
    font-weight: 500;
}

.HomePageHeader__textdes {
    color: $calendar-header-black;
    font-size: 24px;
    font-family: $font-family-sans-serif;
    margin-bottom: 35px;
    line-height: 29px;
    width: 540px;
    padding: 0px 20px;
    text-align: center;

    @media screen and (max-width: 1920px) {
        width: 410px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 24px;
    }

    @media screen and (max-width: 1440px) {
        width: 410px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 22px;
    }

    @media screen and (max-width: 768px) {
        width: 410px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 526px) {
    .HomePageHeader__title {
        font-size: 24px;
        font-family: $font-family-headings;
        line-height: 38px;
        color: $calendar-black;
        margin-bottom: 8px;
    }

    .HomePageHeader__text {
        color: $calendar-blue;
        font-size: 18px;
        font-family: $font-family-sans-serif;
        margin-bottom: 25px;
        line-height: 29px;
    }

    .HomePageHeader__textdes {
        color: $calendar-header-black;
        font-size: 14px;
        font-family: $font-family-sans-serif;
        margin-bottom: 30px;
        line-height: 20px;
        width: 540px;
    }

    .homepage--btn {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100% !important;
    }
}

@media screen and (max-width: 400px) {
    .HomePageHeader__title {
        font-size: 22px;
        line-height: 30px;
    }

    .HomePageHeader__textdes {
        margin-bottom: 20px;
    }
}