@import 'styles/config';

$NavbarNotificationsItem-padding-x: 1.25rem;
$NavbarNotificationsItem-padding-y: 1rem;
$NavbarNotificationsItem-unread-background: #F1F1F1;

.NavbarNotificationsItem {
  font-size: .86rem;
  padding: $NavbarNotificationsItem-padding-y $NavbarNotificationsItem-padding-x !important;
  white-space: normal !important;
  display: flex !important;
  text-decoration: none;
  border-bottom: #e9e9e9 solid 1px !important;

  &--unread.dropdown-item {
    background-color: $NavbarNotificationsItem-unread-background;

    &:hover {
      background-color: darken($NavbarNotificationsItem-unread-background, 5%);
    }
  }

  &__message {
    margin-bottom: $spacer *.125;
    color: #151c2d;
    margin-top: 0.5rem;
    font-size: 14px !important;
  }

  &__icon {
    margin-right: 10px;
  }

  &__time {
    color: #788B9A !important;
    margin: 0;
    margin-top: .625rem !important;
    font-size: 12px;
  }
}
