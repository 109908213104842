$table__cell-padding-left: 1.875rem;
$table__cell-padding-right: 0;
$table__cell-padding-y: 1.25rem;

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px !important;

    thead th {
        padding: 1px 1px 13px 30px;
        border: none;
        background-color: #e9ecef;
        font-size: 14px;
        font-family: $font-family-sub-headings;
        //line-height: 19px;
        color: $calendar-header-black;
        vertical-align: middle;

        @media screen and (max-width: 1440px) {
            font-size: 14px;
        }

        &:last-child {
            padding-right: 30px;
        }
    }

    td {
        padding: $table__cell-padding-y $table__cell-padding-right $table__cell-padding-y $table__cell-padding-left;
        background-color: $white;
        border: none !important;
        vertical-align: middle;

        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 20px 30px 20px 30px;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }

        @media screen and (max-width: 1440px) {
            font-size: 14px;
        }
    }

    &__picture-col {
        vertical-align: middle !important;
        padding-top: 0.125rem !important;
        padding-bottom: 0.125rem !important;
        width: $table-image-size + $table__cell-padding-left;
        min-width: $table-image-size + $table__cell-padding-left;

        &--unset-width {
            width: unset;
            min-width: unset;
        }
    }

    &__picture-list-col {
        vertical-align: middle !important;
        padding-top: 0.125rem !important;
        padding-bottom: 0.125rem !important;
    }

    &__id-col {
        font-size: $font-size-sm;
        width: $spacer * 12;
    }

    &__details-col-sm {
        width: $spacer * 8;
    }

    &__details-col {
        width: $spacer * 16;
    }

    &__title-col {
        width: $spacer * 16;
    }

    &__title-col-lg {
        width: $spacer * 22;
    }

    &__status-col {
        text-align: center;
    }

    &__tags-col {
        width: 300px;
        max-width: 300px;

        .slick-list {
            .slick-track:before,
            .slick-track:after {
                display: block;
            }
        }

        &--lg {
            width: 600px;
            max-width: 600px;
        }
    }

    &__badge-col {
        width: $spacer * 8;
    }

    &__badge-col-lg {
        width: $spacer * 12;
    }

    &__date-col {
        width: $spacer * 8;
    }

    &__date-col-lg {
        width: $spacer * 11;
    }

    &__options-col {
        text-align: center;
        width: $spacer * 7;
    }

    &__btn-col {
        text-align: center;
        width: $spacer * 8;
    }

    &__btn-col2 {
        width: $spacer * 8;
    }

    &__truncate-col {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 700px;
    }

    &__stretch-link-cell {
        padding: 0 !important;
    }

    &__link {
        color: $calendar-black;

        &:hover {
            text-decoration: none;
            color: $calendar-black;
        }

        &--stretch {
            padding: $table__cell-padding-y $table__cell-padding-right $table__cell-padding-y $table__cell-padding-left;
            display: block;
        }
    }
}

.table_scroll_div {
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}

.nowrap {
    white-space: nowrap;
}
