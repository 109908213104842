@import 'styles/config';

.BecomeMember {
  &__headline-section {
    background-color: $gray-F9;
    padding: 6.5rem 0;

    @media screen and (max-width: 1024px) {
      padding: 6.2rem 0;
    }

    @media screen and (max-width: 768px) {
      padding: 6rem 0;
    }

    @media screen and (max-width: 425px) {
      padding: 5rem 0;
    }
  }

  &__feature-list {
    width: 50%;
    margin: auto;
    margin-bottom: 48px;

    @media screen and (max-width: 1024px) {
      width: 70%;
    }

    @media screen and (min-width: 2560px) {
      width: 1200px;
    }

    @media screen and (max-width: 1024px) {
      width: 70%;
    }
  }

  &__description {
    margin-bottom: 40px;
    text-align: justify;

    &--text {
      font-size: 18px;
      line-height: 40px;
      font-family: $font-family-sans-serif;
      padding-bottom: 40px;
      color: $calendar-header-black !important;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
        padding-bottom: 30px;
      }

      @media screen and (max-width: 768px) {
        font-size: 14px;
        padding-bottom: 30px;
        line-height: 35px;
      }

      @media screen and (max-width: 425px) {
        font-size: 12px;
        padding-bottom: 30px;
        line-height: 30px;
      }
    }
  }

  &__booking-button-wrapper {
    margin-bottom: 86px;
    text-align: center;
  }
}
