@import '../../../../styles/config';

.BreadCrumb {
  font-size: 14px;
  margin-bottom: 5rem;

  &--olList {
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style: none;
    padding-left: 0;
  }

  a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    color: $calendar-black;

    &:hover {
      text-decoration: none;
    }

    :active {
      color: $calendar-header-black;
    }
  }

  &__icon {
    padding: 0 1.25rem;
  }

  &__text {
    font-family: $font-family-sub-headings;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;

    &--active {
      color: $calendar-header-black !important;
    }
  }
}
