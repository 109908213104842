@import 'styles/config';

.Tag {
  color: $white !important;
  font-size: 13px !important;
  line-height: 19px;
  font-family: $font-family-headings;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;

  &--xs {
    font-size: 75%;
    padding: $badge-padding-y * 1.5 $badge-padding-x * 2 !important;
  }

  &--sm {
    font-size: 0.8rem;
    padding: $badge-padding-y * 1.5 $badge-padding-x * 2 !important;

    @media screen and (max-width:426px) {
      padding: 0.375em 1.8em !important;
    }
  }

  &--md {
    font-size: 1.275rem;
    padding: $badge-padding-y * 1 $badge-padding-x * 4 !important;
  }

  &--lg {
    font-size: 1.375rem;
    font-weight: 300;
    padding: $badge-padding-y * 2 $badge-padding-x * 4 !important;
  }

  &--xl {
    font-size: .875rem; // 14px
    padding: $badge-padding-y * 2 $badge-padding-x * 4 !important;

    border-radius: 28px !important;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 8px 13px !important;
    line-height: 19px !important;
  }

  &__close-icon {
    color: #ffffff;
    cursor: pointer;
    margin-left: .625rem;
  }
}