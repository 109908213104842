.UploadProfilePicture {
  &__input {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.Uploadbtn {
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 12px !important;
  gap: 10px;

  &__Exsiting {
    white-space: nowrap;
  }
}