@import 'styles/config';

.ProjectSearchController {
  &__create-button {
    text-decoration: none !important;
    color: $body-color !important;
    font-size: 18px;
    font-weight: bold;
  }

  &__selected-project {
    border: 1px solid $gray-400;
    border-radius: .5rem;
    padding: 1rem;
  }
}
