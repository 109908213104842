@import 'styles/config';

.AboutUsPage {
    &__heading {
        display: flex;
        justify-content: center;
        align-items: center;

        &--text {
            font-size: 50px;
            line-height: 76px;
            font-family: $font-family-headings;
            color: $calendar-black;
            padding: 0px 20px !important;
            text-align: center;

            @media screen and (max-width: 1920px) {
                font-size: 40px;
                line-height: 70px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 38px;
                line-height: 68px;
            }

            @media screen and (max-width: 1240px) {
                font-size: 36px;
                line-height: 50px;
            }

            @media screen and (max-width: 768px) {
                font-size: 32px;
                line-height: 50px;
            }

            @media screen and (max-width: 526px) {
                font-size: 28px;
                line-height: 30px;
            }
        }

        &--sub-heading {
            font-size: 18px !important;
            text-align: center;

            @media screen and (max-width: 1920px) {
                font-size: 16px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 16px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }

            @media screen and (max-width: 526px) {
                font-size: 12px;
            }
        }
    }

    &__Legalpractice {
        padding: 112px 0 42px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $gray-F9;

        @media screen and (max-width: 1920px) {
            padding: 90px 0 38px 0;
        }

        @media screen and (max-width: 1440px) {
            padding: 80px 0 36px 0;
        }

        @media screen and (max-width: 768px) {
            padding: 70px 0 32px 0;
        }

        @media screen and (max-width: 526px) {
            padding: 60px 0 30px 0;
        }
    }

    &__card {
        &--group {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        &--container {
            border: 1px solid $gray-E9;
            max-width: 510px;
            height: 402px;
            border-radius: 15px;

            @media screen and (max-width: 1735px) {
                max-width: 420px;
                height: 440px;
            }

            @media screen and (min-width: 1024px) and (max-width: 1391px) {
                max-width: 329px;
                height: 492px;
            }
        }

        &__image {
            &--container {
                text-align: center;
                margin: auto;
                height: 114px;
                width: 114px;
                border-radius: 50%;
                background-color: $gray-F9;
                align-items: center;
                justify-content: center;
                display: flex;
            }
        }

        &__paragraph {
            text-align: center;

            &--text {
                font-size: 20px;
                line-height: 30px;
                font-family: $font-family-sans-serif;
                color: $calendar-header-black;

                @media screen and (max-width: 1920px) {
                    font-size: 18px;
                    line-height: 22px;
                }

                @media screen and (max-width: 1440px) {
                    font-size: 16px;
                    line-height: 22px;
                }

                @media screen and (max-width: 1240px) {
                    font-size: 16px;
                    line-height: 22px;
                }

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    &__paragraph {
        text-align: justify;

        &--text {
            font-size: 22px;
            line-height: 40px;
            // letter-spacing: 2px;
            font-family: $font-family-sans-serif;
            color: $calendar-header-black !important;

            @media screen and (max-width: 1920px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 16px;
                line-height: 22px;
            }

            @media screen and (max-width: 1240px) {
                font-size: 16px;
                line-height: 22px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &__color {
        &--text-151C2D {
            color: $calendar-black;
        }
    }

    &__padding {
        &--a45 {
            padding: 45px;
        }
    }

    &__margin {
        &--t82-b48 {
            margin: 82.5px 0 48.5px 0;

            @media screen and (max-width: 1920px) {
                margin: 70px 0 40px 0;
            }

            @media screen and (max-width: 1440px) {
                margin: 65px 0 35px 0;
            }

            @media screen and (max-width: 768px) {
                margin: 60px 0 30px 0;
            }

            @media screen and (max-width: 526px) {
                margin: 50px 0 30px 0;
            }
        }

        &--t63-b122 {
            margin: 63px 0 122px 0;

            @media screen and (max-width: 1920px) {
                margin: 40px 0 95px 0;
            }

            @media screen and (max-width: 1440px) {
                margin: 35px 0 80px 0;
            }

            @media screen and (max-width: 768px) {
                margin: 30px 0 70px 0;
            }

            @media screen and (max-width: 526px) {
                margin: 30px 0 50px 0;
            }
        }

        &--b60 {
            margin-bottom: 60px;

            @media screen and (max-width: 1920px) {
                margin-bottom: 50px;
            }

            @media screen and (max-width: 1440px) {
                margin-bottom: 40px;
            }

            @media screen and (max-width: 768px) {
                margin-bottom: 30px;
            }

            @media screen and (max-width: 526px) {
                margin-bottom: 20px;
            }
        }

        &--b120 {
            margin-bottom: 120px;

            @media screen and (max-width: 1920px) {
                margin-bottom: 80px;
            }

            @media screen and (max-width: 1440px) {
                margin-bottom: 60px;
            }

            @media screen and (max-width: 768px) {
                margin-bottom: 40px;
            }

            @media screen and (max-width: 526px) {
                margin-bottom: 30px;
            }
        }

        &--b140 {
            margin-bottom: 140px;
        }

        &--t130 {
            margin-top: 130px;

            @media screen and (max-width: 1920px) {
                margin-top: 90px;
            }

            @media screen and (max-width: 1440px) {
                margin-top: 80px;
            }

            @media screen and (max-width: 768px) {
                margin-top: 60px;
            }

            @media screen and (max-width: 526px) {
                margin-top: 5px;
            }
        }

        &--b28 {
            margin-bottom: 28px;
        }

        &--b30 {
            margin-bottom: 30px;
        }

        &--b22 {
            margin-bottom: 22px;
        }

        &--b52 {
            margin-bottom: 52px;
        }
    }
}

@media screen and (max-width: 526px) {
    .AboutUsPage {
        &__heading {
            display: flex;
            justify-content: center;
            align-items: center;

            &--text {
                font-size: 26px;
                line-height: 35px;
                font-family: $font-family-headings;
                color: $calendar-black;
                text-align: center;
            }

            &--sub-heading {
                font-size: 16px !important;
                text-align: center;
            }
        }

        &__Legalpractice {
            padding: 56px 0 24px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $gray-F9;
        }

        &__card {
            &--group {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }

            &--container {
                border: 1px solid $gray-E9;
                max-width: 510px;
                height: 402px;
                border-radius: 15px;
            }

            &__image {
                &--container {
                    text-align: center;
                    margin: auto;
                    height: 114px;
                    width: 114px;
                    border-radius: 50%;
                    background-color: $gray-F9;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                }
            }

            &__paragraph {
                text-align: center;
                display: -webkit-box;
                max-width: 200px;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;

                &--text {
                    font-size: 16px;
                    line-height: 28px;
                    font-family: $font-family-sans-serif;
                    color: $calendar-header-black;
                }
            }
        }

        &__paragraph {
            text-align: justify;

            &--text {
                font-size: 16px;
                line-height: 24px;
                // letter-spacing: 2px;
                font-family: $font-family-sans-serif;
                color: $calendar-header-black !important;
            }
        }

        &__padding {
            &--a45 {
                padding: 45px;


                @media screen and (max-width: 1920px) {
                    padding: 40px;
                }

                @media screen and (max-width: 1440px) {
                    padding: 35px;
                }

                @media screen and (max-width: 526px) {
                    padding: 30px;
                }
            }
        }

        &__margin {
            &--t82-b48 {
                margin: 41px 0 24px 0;
            }

            &--t63-b122 {
                margin: 32px 0 61px 0;
            }

            &--b60 {
                margin-bottom: 30px;
            }

            &--b120 {
                margin-bottom: 60px;
            }

            &--b140 {
                margin-bottom: 70px;
            }

            &--t130 {
                margin-top: 75px;
            }

            &--b28 {
                margin-bottom: 14px;
            }

            &--b30 {
                margin-bottom: 15px;
            }

            &--b22 {
                margin-bottom: 11px;
            }

            &--b52 {
                margin-bottom: 26px;
            }
        }
    }
}

.about_us_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 82.5px 0 53.5px 0;

    .about_us_heading_text {
        color: #151c2d;
        font-weight: 600;
        font-family: $font-family-sans-serif;
        text-align: center;
        font-size: 50px;
        line-height: 67px;
        margin-bottom: 0;
    }
}

.mb_10px {
    margin-bottom: 10px !important;
}

.mb_minus_1rem {
    margin-bottom: -1rem !important;
}

.mb_0px {
    margin-bottom: 0px !important;
}

.why_rightfull_container {
    margin-bottom: 1rem;
}

.ecosystem_container {
    margin-bottom: 20px;
}

.about_us_row_div {
    max-width: 1087px;
}

.why_rightfull_div {
    .about_us_all_card_main_div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0;
    }
}

.about_us_info_text {
    font-size: 25px;
    letter-spacing: 1px;
    line-height: 40px;
    color: #151c2d;
    font-weight: 400;
    font-family: $font-family-sans-serif;
    text-align: justify;
    margin-bottom: 25px;
}

@media screen and(max-width: 400px) {
    .guide_to_choose_heading {
        .guide_to_heading_text {
            font-size: 1.5rem;
        }
    }
}

.AboutUs__tiara {
    margin-top: 350px;
    width: 40%;
    height: 50%;
}

.AboutUs__title {
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0;
}

.AboutUs__executive {
    margin-bottom: 0;
}

.AboutUs__img {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-self: center;
    align-items: center;
}

.AboutUs__chris {
    margin-top: 50px;
    max-width: 80%;
}

.AboutUs__tiara {
    margin-top: 50px;
    width: 80%;
}

.AboutUs__rob {
    margin-top: 50px;
    max-width: 60%;
    max-height: 90%;
}