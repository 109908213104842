.Plans {
    margin-top: 60px;

    @media screen and (max-width: 1240px) {
        margin-top: 50px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 40px;
    }

    @media screen and (max-width: 526px) {
        margin-top: 30px;
    }

    &__ClientService-tab {
        justify-content: center;
        column-gap: 10px;
        border-bottom: none !important;
        margin-bottom: 50px !important;
        max-width: max-content;
        margin-left: auto !important;
        margin-right: auto !important;
        background-color: #F9F9F9;
        padding: 15px !important;
        width: auto;
        border-radius: 45px;

        @media screen and (max-width: 768px) {
            margin-bottom: 40px !important;
        }

        @media screen and (max-width: 526px) {
            margin-bottom: 30px !important;
        }


        .nav-item {
            padding: 18px 58px;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #788B9A;
            border: none !important;
            border-radius: 30.5px !important;
            transition: all 150ms ease-in-out;

            @media screen and (max-width: 1440px) {
                font-size: 18px;
            }

            @media screen and (max-width: 1240px) {
                padding: 16px 40px;
            }

            @media screen and (max-width: 768px) {
                padding: 14px 34px;
                font-size: 16px;
            }

            @media screen and (max-width: 526px) {
                font-size: 14px;
                padding: 12px 24px;
            }
        }

        .nav-item.active {
            color: #0A8BF2 !important;
            box-shadow: 0px 2px 20px 0px #0000000D;
        }

        &--tabpane.show {
            // display: flex !important;
            // justify-content: center;
            // flex-wrap: wrap;
            // row-gap: 36px;
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 20px;

            @media screen and (max-width: 1280px) {
                display: grid !important;
                grid-template-columns: 2fr 2fr;
                gap: 20px;
            }
            @media screen and (max-width: 526px) {
                grid-template-columns: 2fr ;
            }
        }

    }
}