@import 'styles/config';

.postCard_main_div {
    display: block;
    object-fit: cover;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 56%; // this controls aspect ratio
    .postCard_image {
        display: block;
        max-width: 100%;
        max-height: 280px;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-bottom-left-radius: $card-border-radius;
        border-bottom-right-radius: $card-border-radius;
    }
    .MediaCardPicture--placeholder {
        background: $gray-200;
    }
}
.MediaCardPicture {
    display: block;
    object-fit: cover;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 67%; // this controls aspect ratio
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    &__image {
        display: block;
        max-width: 100%;
        height: 332px;
        background-size: contain;
        // background-size: cover;
        background-position: top;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // background-size: cover;
        background-repeat: no-repeat;
        // background-position: center center;
        border-bottom-left-radius: $card-border-radius;
        border-bottom-right-radius: $card-border-radius;
    }

    &--placeholder {
        background: $gray-200;
    }
}
#link_for_card {
    z-index: 2;
}

@media screen and (max-width:426px) {
    .MediaCardPicture {
        display: block;
        object-fit: cover;
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding-bottom: 67%; // this controls aspect ratio
        border-bottom-left-radius: $card-border-radius;
        border-bottom-right-radius: $card-border-radius;
        &__image {
            display: block;
            max-width: 100%;
            height: 226px;
            background-size: cover;
            background-position: top;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // background-size: cover;
            background-repeat: no-repeat;
            // background-position: center center;
            border-bottom-left-radius: $card-border-radius;
            border-bottom-right-radius: $card-border-radius;
        }
    
        &--placeholder {
            background: $gray-200;
        }
    }




    .postCard_main_div {
        display: block;
        object-fit: cover;
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding-bottom: 56%; // this controls aspect ratio
        .postCard_image {
            display: block;
            max-width: 100%;
            max-height: 226px;
    
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            border-bottom-left-radius: $card-border-radius;
            border-bottom-right-radius: $card-border-radius;
        }
        .MediaCardPicture--placeholder {
            background: $gray-200;
        }
    }
}
