@import 'styles/config';

.CaseEstimator_title {
  font-size: 2.5rem;
  font-weight: 300;
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 1.5;
}

.CaseEstimator__activity {
  width: 100%;
  padding: 18px 25px;
  margin: 8px 0;
  border: 2px solid #02c3ee;
  border-radius: 0px;
}

