@import 'styles/config';

.HelpCenterHeader {
  margin-bottom: 3.125rem;

  &__image {
    display: flex;
    justify-content: center;
  }
}
