@import 'styles/config';

.GuideToChoosingQNA {
  margin-bottom: 60px;

  &__question {
    font-size: 40px;
    line-height: 53px;
    color: $calendar-blue;
    font-family: $font-family-headings;
    margin-bottom: 48.5px;
  }
}
