@import 'styles/config';

.ContactPage {
  .MainForm__wrapper {
    .MainForm__default {
      box-shadow: none;

      .MainForm__form {
        padding-top: 82px;
      }
    }
  }

  &__textarea {
    max-width: 100% !important;
    width: 100%;
    height: unset !important;
    font-size: 18px !important;
    border-radius: 13px !important;
    box-shadow: none !important;
    border: 1px solid $gray-E9 !important;

    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $calendar-header-black !important;
      opacity: 1 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $calendar-header-black !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $calendar-header-black !important;
    }
  }

  &__success-image {
    margin-top: 58.7px;
    margin-bottom: 71px;
    max-height: 202.8px;
    max-width: 202.8px;
  }

  &__success-title {
    font-size: 30px;
    font-family: $font-family-headings;
    line-height: 40px;
    color: $calendar-light-green;
    margin-bottom: 48px;
  }

  &__success-description {
    font-size: 25px;
    line-height: 33px;
    font-family: $font-family-sans-serif;
    color: $calendar-black;
    margin-bottom: 117.2px;
  }
}
