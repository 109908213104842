@import 'styles/config';

.HomePageFeatures {
  margin-top: 120px !important;

  @media screen and (max-width:1920px) {
    margin-top: 100px !important;
  }

  @media screen and (max-width:1440px) {
    margin-top: 90px !important;
  }

  @media screen and (max-width:526px) {
    margin-top: 40px !important;
  }


  &__item {
    display: flex;
    align-items: center;
    margin-bottom: $spacer * 15;
    flex-direction: row;

    &:last-child {
      margin-bottom: $spacer;
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      margin-bottom: $spacer;
    }

    &--reverse {
      flex-direction: row-reverse;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }
  }

  &__item-title {
    margin-bottom: $spacer * 4;
  }

  &__item-content {
    text-align: center;
    min-width: 45%;
    font-size: 1.875rem;

    p {
      width: 27rem;
      margin: 0 auto $spacer * 2 auto;
    }
  }

  &__Item-button {
    margin-top: $spacer * 4;
    margin-bottom: $spacer * 4;
  }
}

.__inactive_gif {
  @media screen and (min-width: 1200px) {
    height: 634px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
  }

}