.PlansHeader {
    margin-top: 60px;

    @media screen and (max-width: 1240px) {
        margin-top: 50px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 40px;
    }

    &__ClientService-tab {
        justify-content: center;
        column-gap: 10px;
        border-bottom: none !important;

        .nav-item {
            min-width: 120px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #788B9A;
            border: 1px solid #E9E9E9 !important;
            border-radius: 17.5px !important;
            transition: all 150ms ease-in-out;

            @media screen and (max-width: 1440px) {
                min-width: 100px;
                font-size: 14px;
            }

            @media screen and (max-width: 768px) {
                padding: 6px 10px;
                min-width: 80px;
                font-size: 12px;
            }
        }

        .nav-item.active {
            color: white !important;
            background-color: #788B9A !important;
            border-color: #788B9A !important;
        }
    }
}