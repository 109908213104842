@import 'styles/config';

.StarRating {
  display: flex;
  align-items: center;
  margin-top: $spacer;
  width: 100%;

  &__star {
    color: $gray-E9;
    transition: all .5s;
    font-size: 30px;
    margin-right: 10px;

    &--clickable {
      cursor: pointer;
    }

    &--active,
    &--hover {
      color: $yellow;
    }

    &:hover,
    &--hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(1);
    }

    &--disabled {
      transform: scale(1) !important;
      cursor: default !important;
    }
  }
}
