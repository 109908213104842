@import 'styles/config';

.HelpSearch {
  display: flex;
  align-items: center;
  height: 4.37rem;
  padding: 1.43rem;
  border-radius: 0.5rem;
  width: 65%;
  margin: auto;

  &__dropdown {
    width: 100%;
  }

  &__control-wrapper {
    display: flex;
    align-items: center;
    background: $gray-F9;
    height: 4.37rem;
    padding: 1.43rem;
    border-radius: 0.5rem;
    margin: auto;
  }

  &__icon {
    height: 1.69rem !important;
    width: 1.69rem !important;
    color: $calendar-header-black;
  }

  &__control {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    font-family: 'Poppins', sans-serif;
  }
}
