@import 'styles/config';

$ProfileCard__spacer: $spacer * 0.8;

.ProfileCard {
    text-align: left;
    max-width: 500px !important;
    margin: auto auto 60px;
    box-shadow: none !important;
    width: 100%;
    border: 1px solid $gray-300 !important;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        @media screen and (min-width: 768px) {
            transform: scale(1.02);
        }
    }

    &__card-body {
        z-index: 2;
        padding: 0 !important;
    }

    &__profession {
        text-transform: uppercase;
        font-size: 14px;
        color: $calendar-header-black;
        line-height: 19px;
        font-family: $font-family-sans-serif;
        margin-top: $ProfileCard__spacer;
    }

    &__details {
        padding: 20px;
    }

    &__TagsBar {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__Checkbtn {
        padding: 20px;
        position: relative;

        .connectbtn {
            position: relative;
            font-weight: 600 !important;

            &:hover {
                .DropdownCaret {
                    opacity: 1;
                }
            }

            .DropdownCaret {
                width: 16px;
                height: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                right: 18px;
                color: white;
                opacity: 0;
                transition: transform 250ms ease, opacity 150ms ease;
                transform-origin: center;
            }

            .expanded-DropdownCaret {
                opacity: 1;
                transform: translateY(-50%) rotate(-180deg);
            }
        }

        .connectbtn {
            display: block;
            width: 100%;
            padding: 10px;
            border-radius: 10px;
            color: white;
            font-weight: 500;
            background: linear-gradient(to left, #01c8ee 0%, #0a8bf2 100%);
            transition: background-color 250ms ease, border-radius 250ms ease;
            border: none;
            outline: none;
        }

        .expanded-connectbtn {
            background: #F9F9F9;
            color: #151C2D;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .DropdownCaret {
                color: #151C2D;
            }
        }

        .connectbtnrow {
            height: 0;
            width: calc(100% - 40px);
            display: flex;
            align-items: center;
            column-gap: 10px;
            position: absolute;
            left: 50%;
            top: 78%;
            transform: translateX(-50%);
            border-radius: 0 0 0.375rem 0.375rem;
            overflow: hidden;
            background-color: white;
            opacity: 0;
            padding: 20px;
            pointer-events: none;
            transition: height 350ms ease-out, opacity 400ms ease-out;

            button {
                width: 100%;
                padding: 11px 1rem;
                display: flex;
                align-items: center;
                white-space: nowrap;
                justify-content: center;
                gap: 10px;
                font-size: 15px;
                font-weight: 600;
                background-color: white;
                border: none;
                color: #788B9A;
                box-shadow: none;

                @media screen and (max-width: 450px) {
                    font-size: 14px;
                    padding: 11px 5px;
                    gap: 5px;
                }

                svg {
                    width: 15px;
                    height: 15px;
                }
            }

            @media screen and (max-width: 450px) {
                padding: 10px;
            }
        }

        .expanded-connectbtnrow {
            height: 90px;
            opacity: 1;
            pointer-events: all;
            background-color: #F9F9F9;
        }

        .network-connectbtnrow {
            @media screen and (max-width: 1440px) {
                height: 0;
                row-gap: 10px;
            }

            @media screen and (max-width: 426px) {
                column-gap: 5px;
                padding: 10px;
            }

            @media screen and (max-width: 358px) {
                flex-direction: column;
                min-width: auto;
                column-gap: 5px;
            }
        }

        .expanded-network-connectbtnrow {
            @media screen and (max-width: 1440px) {
                height: 75px;
            }
        }



        .expanded-network-connectbtnrow {
            @media screen and (max-width: 358px) {
                height: 120px;
            }
        }
    }

    &__location {
        color: $text-muted;
    }

    @media only screen and (min-width: 768px) and (max-width: 993px) {
        white-space: nowrap;
    }

    &__title {
        font-size: 24px;
        line-height: 32px;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        font-family: $font-family-headings;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 210px;

        @media screen and (min-width: 1600px) {
            max-width: 350px;
        }

        @media screen and (min-width: 767px) and (max-width: 990px) {
            max-width: 110px;
            font-size: 18px;
        }
    }

    &__star_icon {
        svg {
            height: 27px;
            width: 27px !important;
        }
    }

    &__location-icon {
        margin-right: $spacer * 0.5;
    }
}

.fixed_fee_enum_div {
    margin-top: 9px;
    // display: flex;
    // align-items: center;
    // text-align: end;
    // height: 21px;

    .fixed_fee_enum_svg {

        // margin-right: 20px;
        @media only screen and (min-width: 768px) and (max-width: 993px) {
            // margin-right: 10px;
        }
    }

    .fixed_fee_enum_text {
        font-size: 16px;
        line-height: 21px;
        font-family: $font-family-sans-serif;
    }
}

.text_align_end {
    text-align: end;
}

.flex_row_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flex_colum_end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.Hourly_rate_responsive {
    // width: 180px;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: column;

    @media screen and (min-width: 1199px) and (max-width: 1439px) {
        width: 115px;
    }
}

.margin_right_10 {
    margin-right: 10px;
}

@media screen and (max-width: 450px) {
    .ProfileCard {
        text-align: left;
        max-width: 360px !important;
        margin-bottom: 45px;
        box-shadow: none !important;

        &__card-body {
            z-index: 2;
        }

        &__profession {
            text-transform: uppercase;
            font-size: 13px;
            color: $calendar-header-black;
            line-height: 19px;
            font-family: $font-family-sans-serif;
            margin-top: 0;
        }

        &__Checkbtn {
            padding-top: 5px !important;
        }

        &__details {
            padding: 20px 20px 4px;
        }

        &__location {
            font-size: 13px;
            color: $text-muted;
        }

        &__title {
            font-size: 14px;
            line-height: 32px;
            margin-top: 0;
            margin-bottom: 0;
            font-family: $font-family-headings;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 210px;

            @media screen and (min-width: 1600px) {
                max-width: 350px;
            }
        }

        &__star_icon {
            svg {
                height: 17px;
                width: 17px !important;
            }
        }

        &__location-icon {
            margin-right: $spacer * 0.5;
        }
    }

    .mobileDayHour {
        flex-direction: column;
        justify-content: end;
        align-items: initial;
    }

    .fixed_fee_enum_div {
        margin-top: 9px;
        // display: flex;
        // align-items: center;
        // text-align: end;

        .fixed_fee_enum_svg_icon {
            margin-right: 0;
            height: 15px !important;
            width: 12px !important;
        }

        .fixed_fee_enum_svg {
            // margin-right: 5px;
        }

        .fixed_fee_enum_text {
            font-size: 10px;
            line-height: 21px;
            font-family: $font-family-sans-serif;
        }
    }

    .Hourly_rate_responsive {
        max-width: 180px;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-end;

        @media screen and (min-width: 1199px) and (max-width: 1439px) {
            width: 115px;
        }
    }
}

.disableHover {
    pointer-events: none;
}