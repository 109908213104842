@import 'styles/config';

.ResourcesPageNav {
  font-size: 19px;

  &__link.nav-link {
    height: 35px;
    border: 1px solid $gray-E9;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 14px;
    color: $calendar-header-black;
    margin: 8px;

    &.active {
      background-color: $calendar-header-black;
      color: white;
    }

    &:hover {
      text-decoration: none;
      color: rgb(53, 53, 53);
    }
  }

  &__dropdown-button {
    height: 35px;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: $calendar-header-black;
    margin: 8px;
  }
}
