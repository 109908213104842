.default-desk-class {
    max-width: 1650px;
}
.default-desk-class-3{
  max-width: 1650px;
}
.default-desk-class-2{
    max-width: 1150px;
}
.default-desk-class-1{
    max-width: 560px;

}
.review-desk-class {
    max-width: 1600px;
  }
  .review-desk-class-2{
    max-width: 1200px;
  }
  .review-desk-class-1{
    max-width: 560px;
  }
  .review-slider-item {
    .slick-track {
      width: auto !important;
      .slick-slide {
        min-width: 370px !important;
      }
    }
  }
  .review-slider-more {
    .slick-list {
      display: block !important;
    }
  }

  .homepageSlider{
    max-width: 1100px;
  }