@import 'styles/config';

.GuideToChoosingSymbols {
  padding-bottom: 70px;

  &__header {
    padding-top: 22.5px;
    padding-bottom: 48.5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    color: #151c2d;
    font-family: "Poppins-Semi-Bold", sans-serif;
    text-align: center;
    font-size: 50px;
    line-height: 82px;
  }
}
