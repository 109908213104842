@import 'styles/config';

.ProfilePageHeader {
  text-align: center;
  position: relative;
  width: 100%;
  background-color: #F9F9F9;
  padding: 80px 120px 28px;

  @media screen and (max-width: 1200px) {
    padding-top: 70px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: 450px) {
    &__bottomEven {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
      margin-top: 20px;
      padding: 0px 4px;
    }

    &__bottomOdd {
      display: flex;
      width: 100%;
      gap: 5px;
      justify-content: center;
      margin-top: 20px;
      padding: 0px 4px;
    }

    &__services {
      width: 100%;
      border-radius: 10px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__checkbox {
    .custom-control-label {
      padding-top: 5px;
      padding-left: 0.4rem;
      font-size: 14px;
    }

    .invalid-feedback {
      width: 0;
    }
  }

  &__Bar {
    height: 0.1rem;
    margin-left: 20rem !important;
    margin-right: 0 !important;
    background-color: #788b9a;
  }

  &__BotBar {
    margin-top: 0.5rem;
  }

  &__ProfilePicture {
    float: left;

    &--container {
      display: flex;
      flex-direction: column;
      float: left;
      align-items: center;
    }
  }

  &__leftBox {
    text-align: left;
    margin-left: 3rem;
    height: 223px;
    float: left;
    flex: auto;
    width: 50%;
    height: auto;
  }

  &__rightBox {
    text-align: right;
    float: right;
    width: 30%;
  }

  &__leftBo2x {
    // text-align: left;
    // float: left;
    width: 80%;
  }

  &__rightBo2x {
    text-align: right;
    float: right;
    flex: auto;
    width: 20%;

    &--px4 {
      margin-right: 4px;
    }
  }

  &__title {
    font-family: $font-family-headings;
    font-weight: 600;
  }

  &__booking {
    width: 100% !important;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;

    img {
      width: 30px;

      @media screen and (max-width: 1920px) {
        width: 22px;
      }
    }

    .connectbtn {
      position: relative;
      font-weight: 600 !important;

      &:hover {
        .DropdownCaret {
          opacity: 1;
        }
      }

      .DropdownCaret {
        width: 16px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(0deg);
        right: 18px;
        color: white;
        opacity: 0;
        transition: transform 250ms ease, opacity 150ms ease;
        transform-origin: center;
      }

      .expanded-DropdownCaret {
        opacity: 1;
        transform: translateY(-50%) rotate(-180deg);
      }
    }

    .connectbtn {
      display: block;
      width: 100%;
      max-width: 340px;
      min-width: 340px;
      margin-left: auto;
      padding: 10px;
      border-radius: 10px;
      color: white;
      font-weight: 500;
      background: linear-gradient(to left, #01c8ee 0%, #0a8bf2 100%);
      transition: background-color 250ms ease, border-radius 250ms ease;
      border: none;
      outline: none;

      @media screen and (max-width: 1050px) {
        margin: auto;
      }

      @media screen and (max-width: 375px) {
        min-width: auto;
      }
    }

    .expanded-connectbtn {
      background: #F9F9F9;
      color: #151C2D;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .DropdownCaret {
        color: #151C2D;
      }
    }

    .connectbtnrow {
      height: 0;
      width: 100%;
      max-width: 340px;
      min-width: 340px;
      display: flex;
      align-items: center;
      // row-gap: 10px;
      column-gap: 3px;
      position: absolute;
      top: 100%;
      border-radius: 0 0 0.375rem 0.375rem;
      overflow: hidden;
      background-color: white;
      opacity: 0;
      padding: 20px;
      pointer-events: none;
      transition: height 350ms ease-out, opacity 400ms ease-out;

      @media screen and (max-width: 345px) {
        flex-direction: column;
        min-width: auto;
        gap: 5px;
      }

      button {
        width: 100% !important;
        padding: 11px 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        font-size: 15px;
        font-weight: 600;
        background: white;
        border: none;
        color: #788B9A;
        box-shadow: none;

        svg {
          min-width: 15px;
          height: 15px;
        }
      }
    }

    .expanded-connectbtnrow {
      height: 70px;
      opacity: 1;
      background-color: #F9F9F9;
      pointer-events: all;

      @media screen and (max-width: 345px) {
        padding: 14px;
        height: 127px;
      }
    }

    .btn_bookdiary {
      white-space: nowrap;
    }
  }

  &__rate {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__rateDay {
    float: inline-end;
    color: rgb(93, 180, 93);
    font-family: $font-family-sans-serif;
  }

  &__rateHr {
    float: inline-end;
    color: rgb(93, 128, 180);
  }

  &__rateFixed {
    margin-top: 3.5rem;
    color: rgb(209, 56, 56);
  }
}

.ProfilePageHeader__profession {
  font-family: $font-family-headings;
  font-size: 18px;

  font-weight: normal;
  color: #788b9a;
  text-transform: uppercase !important;

  @media screen and (max-width: 1920px) {
    font-size: 16px;
  }
}

.ProfilePageHeader__location {
  font-size: 14px;
  margin-bottom: $spacer;
  color: #788b9a;
}

.ProfilePageHeader__location-icon {
  margin-right: 9px;
  width: 12.5px;
}

.ProfilePageHeader__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacer;
}

.bageName___media_card {
  margin-top: 16px;
}

.TagId {
  margin-left: 35px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0 !important;
  flex-direction: row;

  &--height {
    height: 56px;
  }
}

.ProfilePageHeader__tag {
  margin-right: $spacer;
  margin-bottom: $spacer;

  &:last-child {
    margin-right: 0;
  }
}

.ProfilePageHeader__editable-controls {
  position: absolute;
  top: 0;
  right: 0;
}

.LINKEDIN {
  src: 'assets/homepage/Footer/Icon awesome-facebook-square.png';
  height: 25px;
  width: 25px;
}

.ProfilePage_FixedFeeIcon {
  width: 22px;
  height: 28px;
  margin-right: 10px;
}

.ProfilePageHeader__title2 {
  font-family: 'Poppins-Semi-Bold', sans-serif;
  font-weight: 600;
  font-size: 34px;

  @media screen and (max-width: 1920px) {
    font-size: 30px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 28px;
  }
}

.FixedFee {
  font-size: 18px;
  font-weight: bold;

  @media screen and (max-width: 1920px) {
    font-size: 16px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
}

.imagesizeProfilepage {
  width: 19px;
  height: 19px;
  margin-right: 8px;
}

.imagesizeProfilepage2 {
  width: 14px;
  height: 19px;
  margin-right: 8px;
}

.downlodBTN {
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}

.colWidth {
  width: 280px;
  margin-left: 0 !important;
}

.MobilezViewShow {
  display: none;
}

.IdLinkPadd {
  padding-right: 10px;
}

@media screen and (max-width: 1050px) {
  .MobileView {
    display: none;
  }

  .MobilezViewShow {
    display: block;
  }


  .ProfilePageHeader {
    text-align: center;
    position: relative;
    width: 100%;
    background-color: #F9F9F9;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &__Bar {
      // height: 0.1rem;
      margin-left: 0 !important;
      margin-right: 0 !important;
      background-color: #e9e9e9;
      padding: 0 !important;
    }

    &__profiledetails {
      display: flex;
      align-items: start;
      gap: 20px;
    }

    &__leftBox {
      text-align: left;
      margin-left: 12px;
      margin-bottom: 0 !important;
      height: 100%;
      float: left;
      flex: auto;
    }

    &__rightBox {
      text-align: right;
      float: right;
      width: 10%;
    }

    &__BotBar {
      margin-top: 0.5rem;
      margin-left: 20px !important;
      margin-right: 20px !important;
    }

    &__rate {
      margin-top: 12px;
      margin-bottom: 13px;
      font-size: 14px;
    }

    &__fee {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__booking {
      width: 100% !important;
      font-size: 16px !important;
      display: flex;
      justify-content: center;
    }

    &__rateDay {
      display: flex;
      justify-content: flex-end;
      color: rgb(93, 180, 93);
      font-family: $font-family-sans-serif;
    }

    &__rateHr {
      float: inline-end;
      color: rgb(93, 128, 180);

    }

    &__rightBo2x {
      text-align: right;
      float: right;
      width: 50% !important;
    }

    &__leftBo2x {
      text-align: left;
      float: left;
      width: 50% !important;
    }

  }

  .ProfilePageHeader__profession {
    font-family: $font-family-headings;
    font-size: 14px;
  }

  .ProfilePageHeader__title2 {
    font-weight: 600;
    font-size: 19px;
    word-break: break-all;

  }

  .ProfilePageHeader__location-icon {
    margin-right: 5px;
    width: 12.5px;
  }

  .FixedFee {
    font-size: 12 px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .ProfilePage_FixedFeeIcon {
    width: 12px;
    height: 15px;
    margin-right: 10px;
  }

  .ProfilePageHeader__location {
    font-size: 12px;
    margin-bottom: 11px;
    color: #788b9a;
  }

  .ProfilePageHeader__tags {
    margin-top: $spacer;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex !important;
  }

  .TagMobile {
    justify-content: center !important;
    align-items: center !important;
    padding: 8px 16px !important;
    margin-left: 5px;
    font-size: 12px !important;
    margin-bottom: 10px;
  }

  .bageName {
    padding-left: 20px;
    padding-right: 20px;
  }

  .imagesizeProfilepage {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .TagLines {
    font-size: 13px;
    // display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .TagLinesImg {
    margin-bottom: 0;
  }

  .downlodBTN {
    font-size: 13px;
    margin-right: 10px;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px !important;
    }
  }

  .IdLinkPadd {
    padding-right: 10px;
    height: 14px;
    width: 14px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .ProfilePageHeader__editable-controls {
    position: absolute;
    top: 20px;
    right: 20px;

    img {
      width: 20px;
    }
  }

  .availabilityBtn {
    padding: 18px !important;
    width: 100% !important;
    margin-left: 0 !important;
  }
}