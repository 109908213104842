@import 'styles/config';

$SignupPage__checkbox-size: 2.5rem; // 40px
$SignupPage__checkbox-label-spacer: 0.9375rem; // 15px
$SignupPage__checkbox-border-radius: 0.625rem; // 10px
$SignupPage__checkbox-total-height: $SignupPage__checkbox-size + .25rem; // checkbox size + (border-width * 2)

.SignupPage {

  &__form-footer {
    margin-top: 40.5px;
    margin-bottom: 49.7px;
  }

  &__form-heading {
    text-align: left;
    font-size: 20px;
    line-height: 27px;
    color: $calendar-black;
    font-family: $font-family-sub-headings;
    margin-bottom: 20px;
  }

  &__checkbox.custom-control.custom-checkbox {
    padding-left: $SignupPage__checkbox-size;
    min-height: $SignupPage__checkbox-total-height;

    .custom-control-label {
      min-height: $SignupPage__checkbox-total-height;
      margin-left: $SignupPage__checkbox-label-spacer;
      padding-top: 10px;

      &::before,
      &::after {
        left: -$SignupPage__checkbox-size - $SignupPage__checkbox-label-spacer;
        width: $SignupPage__checkbox-size;
        height: $SignupPage__checkbox-size;
        border-radius: $SignupPage__checkbox-border-radius;
      }
    }
  }

  &__footer-note {
    text-align: left;
    margin-top: 50px;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
    font-family: $font-family-sans-serif;
    color: $calendar-black;
  }

  &__terms-link {
    text-decoration: underline;
  }

  &__profession-group {
    margin-bottom: 37px;

    .form-check-inline .form-check-input {
      margin-right: 15px;
    }

    label {
      margin-top: 1px;
    }

    input[type='radio'] {
      -webkit-appearance: none;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      outline: none;
      border: 1px solid $calendar-header-black;
    }

    input[type='radio']:before {
      content: '';
      display: block;
      width: 17px;
      height: 17px;
      margin: 24.5% auto;
      border-radius: 50%;
    }

    input[type='radio']:checked:before {
      background: $calendar-blue;
    }

    input[type='radio']:checked {
      border-color: $calendar-blue;
    }

    .checkbox label {
      margin-bottom: 20px !important;
    }
  }
}
