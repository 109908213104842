@import 'styles/config';

.MarketplaceFilter {
    display: none !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        display: flex !important;
    }

    @media screen and (min-width: 768px) {
        margin-top: 100px;
        margin-bottom: 60px;
        padding: 0px 30px;
    }

    @media screen and (max-width: 1600px) {
        flex-direction: column;
        justify-content: center;
    }

    &__badgefilter {
        display: none !important;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            display: flex !important;
        }

        .BadgeFilter {
            margin-bottom: 0;
        }
    }

    &__badgefilter--mobile {
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            display: flex !important;
        }

        .BadgeFilter {
            margin-bottom: 0;
        }
    }

    &__container {
        display: none !important;

        @media screen and (min-width: 768px) {
            width: 100%;
        }

        @media screen and (min-width: 1050px) {
            width: 930px;
        }

        @include media-breakpoint-up(md) {
            display: flex !important;
        }

        // TODO: Fix when enabling overlay for mobile
        // position: fixed;
        // background-color: white;
        // z-index: 100;
        // height: 100vh;
        // width: 100%;
        // margin-top: 376px;

        // &--show {
        //  display: inline !important;
        // }
    }

    &__label {
        font-size: 14px;
        font-family: $font-family-sub-headings;
        line-height: 19px;
        color: $calendar-header-black;
        margin-right: 25px;

        @media screen and (max-width: 426px) {
            font-size: 12px;
            font-family: $font-family-sub-headings;
            line-height: 19px;
            color: $calendar-header-black;
            margin-right: 25px;
        }
    }

    &__badge-group {
        display: flex;
        align-items: center;

        @media screen and (max-width: 1600px) {
            margin-bottom: 60px;
        }
    }

    .flex_align_row_center {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;

            .pill_select_main_div {
                margin: 20px auto;
            }

            .rangeslider {
                margin: 20px auto;

                .label_select_text {
                    margin-bottom: 30px;
                }
            }
        }

        // @media screen and (max-width: 1050px) {
        //     flex-direction: column;
        //     justify-content: center;

        //     .pill_select_main_div {
        //         margin: 20px auto;
        //     }

        //     .rangeslider {
        //         margin: 20px auto;

        //         .label_select_text {
        //             margin-bottom: 30px;
        //         }
        //     }
        // }
    }

    .margin_right_24 {
        margin-right: 24px;
    }
}

.filterClose {
    display: none !important;
}

.mainFilter {
    display: none !important;
}

.FilterMobileGutterSpace {
    margin-bottom: 15px;
}

@media screen and (max-width: 426px) {
    .MarketplaceFilter {
        margin-top: 20px;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 1600px) {
            flex-direction: column;
            justify-content: center;
        }

        &__badge-group {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-bottom: 10px;

            @media screen and (max-width: 380px) {
                margin-bottom: 10px;
            }
        }

        .sliderMargin {
            margin-left: 20px !important;
            margin-right: 20px !important;
        }

        .flex_align_row_center {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            @media screen and (max-width: 1050px) {
                flex-direction: column;
                justify-content: center;

                .pill_select_main_div {
                    margin: 20px auto;
                }

                .rangeslider {
                    margin: 00px auto;
                    width: 90% !important;

                    .label_select_text {
                        margin-bottom: 30px;
                        font-size: 14px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .filterClose {
        display: flex !important;
        width: 100%;
        border-bottom: 1px solid rgba(120, 126, 135, 0.2);
        padding: 20px;
        margin-left: 0 !important;
    }

    .closeIcon {
        width: 17px;
        height: 17px;
    }

    .btnFilter {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            width: 160px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 14px;
            border-radius: 10px;
            font-weight: 600;
            margin-left: 10px;
        }
    }

    .resetBtn {
        background-color: #788b9a;
    }

    .applyBtn {
        background: linear-gradient(90deg, #0a8bf2 0%, #01cbee 100%);
    }

    .mainFilter {
        display: flex !important;
        position: absolute;
        bottom: 20px;
    }
}