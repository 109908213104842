@import 'styles/config';

.ReviewCard {
  box-shadow: none !important;
  border-width: 0.1rem !important;
  border-color: #d6d5d5 !important;
  text-align: left;
  transition: all 0.15s ease-in-out;
  padding: 10px;
  max-width: 604px;

  &__author-profession {
    font-size: 12px;
    color: $component-active-bg;
    padding-left: 10px;
    height: 15px;
  }

  &__author {
    margin-bottom: 0;
    font-weight: bold;
    color: $calendar-black;
  }

  &__summary {
    font-weight: normal;
    font-size: 16px;
    height: 253px;
    white-space: unset;
    overflow: scroll;
    text-overflow: ellipsis;
    text-align: justify;
    padding: 15px 0 15px 0;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #ff0000;
    }

    @media screen and (max-width: 1050px) {
      font-size: 14px;
    }
  }

  &__quote-icon {
    width: auto;

    @media screen and (max-width: 1050px) {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (max-width: 426px) {
    box-shadow: none !important;
    border-width: 0.1rem !important;
    border-color: #d6d5d5 !important;
    text-align: left;
    transition: all 0.15s ease-in-out;
    padding: 1px;

    &__summary {
      font-weight: normal;
      height: 150px !important;
      font-size: 12px;
      padding: 15px 0 15px 0;
    }

    &__author {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 16px;
      color: $calendar-black;
    }

    &__quote-icon {
      width: 26px;
    }
  }
}