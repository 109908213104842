@import '../../../../styles/config';

.MainForm {
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__default {
    width: 100%;
    box-shadow: $card-box-shadow;

    &--50 {
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    &--60 {
      @include media-breakpoint-up(md) {
        width: 60%;
      }
    }

    &--80 {
      @include media-breakpoint-up(md) {
        width: 80%;
      }
    }
  }

  &__form {
    padding: $spacer * 1;

    &--warning {
      background-color: $warning;
    }
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 300;
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 1.5;
  }

}

.update-profile-tags .form-check {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
}

.form-check-input {
  margin-left: -1rem;
}
