@import 'styles/config';

.AvailableDate {
  &__item {
    width: 102px;
    height: 40px;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $calendar-black;
    border: 1px solid $gray-E9;
    font-size: 16PX;
    margin: 5px;
    border-radius: 13px;
    cursor: pointer;

    &--selected {
      color: $calendar-blue;
      border-color: $calendar-blue;
    }

    &:hover {
      color: $calendar-blue;
      border-color: $calendar-blue;
    }

    &--not-available {
      color: $gray-E9;
      border-color: $gray-E9;
      pointer-events: none;
      cursor: default;

      &:hover {
        color: $gray-E9;
        border-color: $gray-E9;
      }
    }
  }
}
