@import 'styles/config';

.DevelopmentPipeline {
  &__description {
    font-size: 1.375rem;
    font-family: $font-family-sans-serif;
    line-height: 2.5rem;
    text-align: center;
    color: $calendar-header-black;
  }

  &__center--image {
    text-align: center;
    margin-top: 5.625rem;
    padding-bottom: 2.5225rem;
  }

  &__text-regular {
    font-size: 1rem;
    font-family: $font-family-sans-serif;
    line-height: 2rem;
    color: $calendar-black;
  }

  &__text-medium {
    font-size: 1.625rem;
    font-family: $font-family-headings;
    line-height: 2.43rem;
    color: $calendar-black;
  }

  &__image {
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  &__feature {
    display: flex;
    flex-direction: row;
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    max-width: 1920px;
    margin: auto;

    &--image-section {
      width: 50%;
    }

    &--info-section {
      width: 50%;
      padding: 48.2px 6.32% 0 11.5%;
    }

    &--image-box {
      position: relative;
      min-height: 500px;
    }

    &--image {
      width: 100%;
      height: 100%;
      padding: 1.8rem;
      box-shadow: 12px 12px 60px rgb(0 0 0 / 6%);
      background-color: white;
      border-radius: 15px;
      border: 1px solid $gray-F9;
      z-index: 1;
      position: absolute;

      &--left-top {
        left: 0;
        top: 0;
      }

      &--right-top {
        right: 0;
        top: 0;
      }
    }

    &--gray-box {
      height: 400px;
      width: 75%;
      background: $gray-F9;
      position: absolute;
      z-index: -1;

      &--right-bottom {
        right: 0;
        bottom: 0;
      }

      &--left-bottom {
        left: 0;
        bottom: 0;
      }
    }

    &--tag {
      padding: 0.5rem 1rem;
      font-size: 0.875rem;
      font-family: $font-family-sub-headings;
      line-height: 1.25rem;
      color: $white;
      max-width: fit-content;
      border-radius: 1.75rem;
      margin-bottom: 0.812rem !important;

      &--red {
        background-color: $calendar-red;
      }

      &--green {
        background-color: $calendar-light-green;
      }
    }
  }
}
