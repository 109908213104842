@import 'styles/config';

.faq__title {
    font-size: 40px;
    font-weight: 600;
    color: #151C2D;
    padding-bottom: 60px;
    text-align: center;
    line-height: 76px;
    font-family: $font-family-headings;
    color: $calendar-black;

    @media screen and (max-width: 1920px) {
        font-size: 40px;
        line-height: 70px;
        padding-bottom: 40px;
    }

    @media screen and (max-width: 1440px) {
        font-size: 38px;
        line-height: 68px;
    }

    @media screen and (max-width: 1240px) {
        font-size: 36px;
        line-height: 50px;
    }

    @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 50px;
    }

    @media screen and (max-width: 526px) {
        font-size: 28px;
        line-height: 30px;
    }
}

.faq-container {
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 100px;
}

.faq-item {
    border-bottom: 1px solid #ddd;
    padding: 15px 0px;

    .faq-question {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        font-size: 24px;
        font-weight: 600;
        color: #788B9A;

        @media screen and (max-width: 1920px) {
            font-size: 22px;
            line-height: 50px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 20px;
            line-height: 40px;
        }

        @media screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 30px;
        }

        @media screen and (max-width: 526px) {
            font-size: 16px;
            line-height: 30px;
        }
    }


    .faq-answer {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.7s ease, opacity 0.7s ease;
        padding: 0;
        font-size: 20px;
        color: #788B9A;

        &.show {
            max-height: 200px; // Adjust based on expected content height
            opacity: 1;
            // padding-bottom: 10px;
            padding-top: 8px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 18px;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
        }

        @media screen and (max-width: 526px) {
            font-size: 14px;
        }
    }

    .faq-icon {
        font-size: 18px;
        color: #788B9A;
    }

    // .active .faq-icon {
    //     transform: rotate(180deg);
    // }

    .active {
        color: #212529;
    }
}