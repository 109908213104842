@import 'styles/config';

@keyframes UploadImgAnimation {
    from {
        height: 90px;
    }
    to {
        height: 60px;
    }
}

    .modal-backdrop.show {
        opacity: 0.5 !important;
        z-index: 1050 !important;
    }
.UploadImg {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #788b9a;
    border: #e9e9e9 1px solid;
    &--sort {
        height: 60px;
        animation: UploadImgAnimation 0.5s;
    }
    img {
        margin-right: 13px;
    }
}
@keyframes ProgressBarAnimation {
    from {
        width: 0%;
    }
    to {
        width: 80%;
    }
}

.LegalMatter {
    &__UploadImg {
        &--container {
            display: flex;
            width: 100%;
            margin-bottom: 8px;
            margin-top: 8px;
        }
        &--left {
            width: 40px;
        }
        &--center {
            width: calc(100% - 60px);
        }
        &--right {
            width: 20px;
            cursor: pointer;
        }
        &--Cancel-icon {
            transform: scale(1.5);
        }
        &--text {
            font-size: 10px;
            font-family: $font-family-sub-headings;
            line-height: 16px;
        }
        &--ProgressBar {
            margin-top: 7px;
            .progress {
                background-color: $gray-E9;
                .progress-bar {
                    width: 0%;
                    height: 3px;
                    background-color: $calendar-blue !important;
                }
            }
            .progress {
                background-color: $gray-E9;
                .progress-bar-complete {
                    width: 80%;
                    transition: all 0.7s;
                    height: 3px;
                    background-color: $calendar-blue !important;
                    animation-name: ProgressBarAnimation;
                    animation: ProgressBarAnimation 1s;
                }
            }
            &--active {
                .progress {
                    .progress-bar {
                        width: 100%;
                        transition: all 0.7s;
                        background-color: $calendar-light-green !important;
                    }
                }
            }
        }
    }
}
