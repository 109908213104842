@import 'styles/config';

.BecomeMemberFeature {
  padding-bottom: 3.2rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 2.5rem;
  }

  &__check-icon-wrapper {
    margin-right: 1.2rem;
  }

  &__check-icon {
    height: 1.6rem;
    width: 1.6rem;

    @media screen and (max-width: 1024px) {
      height: 1.4rem;
      width: 1.4rem;
    }
  }
}
