@import 'styles/config';

$PostCarousel__loading-spacer: $spacer * 8;

.PostCarousel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PostCard {
    max-width: 500px !important;
    margin: auto !important;
    margin-bottom: 20px !important;
    box-shadow: none !important;
    border: 1px solid $gray-E9 !important;

    &__wrap--div {
        &:focus-visible {
            outline: none !important;
        }
    }
}

.PostCardViws {
    max-width: 490px !important;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px !important;
    box-shadow: none !important;
    border: 1px solid $gray-E9 !important;
}

.tag_div_main_class {
    padding: 0px 1.25rem;

    .MediaCard__tags {
        margin-bottom: 14px;
    }
}

.PostCard__summary {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1px;
    font-family: 'Poppins';
    color: $calendar-header-black;
    // @media screen and (max-width: 768px) {
    //     margin-bottom: 10px;
    //     line-height: 22px;
    // }
}

.PostCard__read-more-link {
    color: $calendar-header-black;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1px;
    font-family: 'Poppins';
}

.postCard_title_margin_alternative {
    min-height: 24px;
    height: 24px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        min-height: 10px;
        height: 10px;
    }

    @media screen and (max-width: 426px) {
        min-height: 13px;
        height: 13px;
        // margin-bottom: 13px;
    }
}

.postCard_title_class {
    font-size: 24px;
    font-family: $font-family-headings;
    line-height: 32px;
    height: 85px;
    max-height: 85px;
    padding: 1.25rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // margin-bottom: 28px;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    @media screen and (max-width: 1050px) {
        font-size: 20px;
        line-height: 28px;
        padding: 10px;
        height: auto;
    }

    @media screen and (max-width: 768px) {
        // margin-bottom: 10px;
        line-height: 28px;
        height: auto;
    }

    @media screen and (max-width: 426px) {
        font-size: 14px;
        // margin-bottom: 13px;
        line-height: 15px;
    }
}

.PostCarouselContent {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    .card:hover {
        cursor: pointer;
        transform: scale(1.02, 1.02);
        box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.4);
    }

    @each $breakpoint,
    $container-max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            max-width: $container-max-width;
        }
    }
}

.PostCarouselContent--loading {
    margin-top: $PostCarousel__loading-spacer;
    margin-bottom: $PostCarousel__loading-spacer;
    padding-top: $PostCarousel__loading-spacer;
    padding-bottom: $PostCarousel__loading-spacer;
}

.PostCard_Footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;

    @media screen and (max-width: 1050px) {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }

    .MediaCard__footer {
        // margin-top: 40px;
    }
}

.CardCarousel__content {
    .card-deck {
        display: block;

        .slick-slider {
            .slick-list {
                max-width: 1800px;
                margin: auto;

                @media screen and (max-width: 1800px) {
                    max-width: 1200px;
                    margin: auto;
                }

                @media screen and (max-width: 1400px) {
                    max-width: 800px;
                    margin: auto;
                }

                @media screen and (max-width: 950px) {
                    max-width: 550px;
                    margin: auto;
                }

                @media screen and (max-width: 700px) {
                    max-width: 426px;
                    margin: auto;
                }

                @media screen and (max-width: 600px) {
                    max-width: 332px;
                    margin: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 426px) {
    .PostCard_Footer {
        .MediaCard__footer {
            // margin-top: 10px;
            padding-top: 0;
        }
    }

    .postcard_text_name {
        font-size: 12px !important;
        font-family: $font-family-sans-serif;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
    }

    .PostCard_Footer_Margin_Alternative {
        min-height: 40px;
        height: 40px;
        cursor: pointer;

        @media screen and (max-width: 426px) {
            min-height: 10px !important;
            height: 10px !important;
        }
    }

    .postcard_text_name {
        font-size: 18px;
        font-family: $font-family-sans-serif;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
    }

    .postcard_text_name {
        font-size: 18px;
        font-family: $font-family-sans-serif;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
    }

    .PostCarousel__button {
        align-items: center;
        display: flex;
        padding: 0 $spacer * 2;
    }

    .PostCarousel__button-image {
        transition: all 0.1s ease-in-out;
        height: 5.9375rem;
    }

    .PostCarousel__button:hover .PostCarousel__button-image {
        transform: scale(1.2);
    }

    .PostCarousel__button:active .PostCarousel__button-image {
        transform: scale(1);
    }


}