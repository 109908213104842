@import 'styles/config';

.our_people_card {
    box-shadow: none !important;
    border-radius: none !important;
}

.our_people_card_img {
    height: 470px;
    border-radius: 7px !important;
    object-fit: cover;

    @media screen and (max-width: 1920px) {
        height: 450px;
    }

    @media screen and (max-width: 1440px) {
        height: 430px;
    }

    @media screen and (max-width: 768px) {
        height: 400px;
    }

    @media screen and (max-width: 526px) {
        height: 350px;
    }
}

.about_us_row_div_container_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.about_us_op_row_div {
    max-width: 1240px;
    margin-top: 50px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    display: grid !important;
    grid-template-columns: auto auto !important;

    @include media-breakpoint-down(md) {
        grid-template-columns: auto !important;
    }
}

.our_people_card {
    min-width: 350px !important;
    max-width: 510px;
    margin: 1rem auto;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    // @media screen and (max-width: 526px) {
    //     max-width: 350px;
    // }

    .card_body_class {
        padding: 0 !important;
        text-align: center;

        .our_people_name {
            font-size: 24px;
            line-height: 32px;
            color: #151c2d;
            font-weight: 600;
            font-family: $font-family-sans-serif;
            margin-top: 36px;

            @media screen and (max-width: 526px) {
                font-size: 18px;
                line-height: 28px;
                margin-top: 18px;
            }
        }

        .our_people_role {
            font-size: 18px;
            line-height: 24px;
            color: #0990f2;
            font-weight: 400;
            font-family: $font-family-sans-serif;
            margin-top: 5px;

            @media screen and (max-width: 1920px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 16px;
                line-height: 22px;
            }

            @media screen and (max-width: 1240px) {
                font-size: 16px;
                line-height: 22px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .our_people_description {
            font-size: 20px;
            line-height: 30px;
            color: #788b9a;
            font-weight: 400;
            font-family: $font-family-sans-serif;
            text-align: center;
            margin-top: 23px;
            text-align: justify;

            @media screen and (max-width: 1920px) {
                font-size: 18px;
                line-height: 25px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 16px;
                line-height: 25px;
            }

            @media screen and (max-width: 1240px) {
                font-size: 16px;
                line-height: 25px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}