.form-control--auth {
    @extend .form-control-lg;

    border-radius: 2rem;
    border: none;
    font-weight: bold;

    &::placeholder {
        color: #c4c4c4;
    }
}

.col-form-label--auth {
    color: $primary;
    font-size: $font-size-lg;
    font-weight: bold;
}

.form-label {
    font-weight: 600;
    font-size: 0.875rem;

    &--sm {
        color: $gray-550;
        font-size: 0.75rem; // 12px;
    }
}

.custom-control.custom-checkbox {
    .custom-control-label {
        cursor: pointer;

        &::before {
            background-color: $white;
        }

        &::after {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border: 2px solid $gray-E9;
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &::after {
            border: 2px solid #1994f0;
        }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none;
    }

    .custom-control-input:disabled {
        &:checked ~ .custom-control-label::before {
            background: $white;
        }
        &:checked ~ .custom-control-label::after {
            //border-color: $gray-500;
            opacity: 0.4;
        }
    }
}

.custom-control-label--checkbox-only {
    &:before,
    &:after {
        left: -$custom-control-indicator-size;
    }
}

.form-control {
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $calendar-header-black !important;
        opacity: 1 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $calendar-header-black !important;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $calendar-header-black !important;
    }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
    padding-left: $custom-control-indicator-size-lg + $custom-control-gutter;

    .custom-control-label::before {
        // stylelint-disable-next-line property-disallowed-list
        border-radius: $custom-radio-indicator-border-radius;

        // the following styles only change the size:
        top: ($font-size-base * $line-height-base - $custom-control-indicator-size-lg) / 2;
        left: -($custom-control-gutter + $custom-control-indicator-size-lg);
        width: $custom-control-indicator-size-lg;
        height: $custom-control-indicator-size-lg;
        @media screen and (max-width: 575px) {
            top: ($font-size-base * $line-height-base - $custom-control-indicator-size-lg + 0.4rem) / 2;
            left: -($custom-control-gutter + $custom-control-indicator-size-lg - 0.5rem);
            width: $custom-control-indicator-size-lg - 0.5rem;
            height: $custom-control-indicator-size-lg - 0.5rem;
        }
    }
    .custom-control-label::after {
        // the following styles only change the size:
        top: ($font-size-base * $line-height-base - $custom-control-indicator-size-lg) / 2;
        left: -($custom-control-gutter + $custom-control-indicator-size-lg);
        width: $custom-control-indicator-size-lg;
        height: $custom-control-indicator-size-lg;
        @media screen and (max-width: 575px) {
            top: ($font-size-base * $line-height-base - $custom-control-indicator-size-lg + 0.4rem) / 2;
            left: -($custom-control-gutter + $custom-control-indicator-size-lg - 0.5rem);
            width: $custom-control-indicator-size-lg - 0.5rem;
            height: $custom-control-indicator-size-lg - 0.5rem;
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &::before {
            background-color: $white;
        }

        &::after {
            background-image: escape-svg($custom-radio-indicator-icon-checked);
        }
    }

    .custom-control-input:disabled {
        &:checked ~ .custom-control-label::before {
            @include gradient-bg($custom-control-indicator-checked-disabled-bg);
        }
    }
}

// switches
//
// Tweak a few things for switches

.custom-switch {
    padding-left: $custom-switch-width + $custom-control-gutter;

    .custom-control-label {
        &::before {
            left: -($custom-switch-width + $custom-control-gutter);
            width: $custom-switch-width;
            pointer-events: all;
            // stylelint-disable-next-line property-disallowed-list
            border-radius: $custom-switch-indicator-border-radius;
            opacity: 0.6;
            background-color: $gray-550;
            border: none;
            height: $custom-switch-height;
            top: ($font-size-base * $line-height-base - $custom-switch-height) * 0.5;
            @media screen and (max-width: 575px) {
                width: $custom-switch-width - 0.5rem;
                height: $custom-switch-height - 0.5rem;
            }
        }

        &::after {
            top: ($custom-switch-height - $custom-switch-indicator-size) * 0.25;
            left: -($custom-switch-width + $custom-control-gutter + 0.125rem);
            width: $custom-switch-indicator-size;
            height: $custom-switch-indicator-size;
            background-color: $gray-550;
            // stylelint-disable-next-line property-disallowed-list
            border-radius: $custom-switch-indicator-border-radius;
            @include transition(transform 0.15s ease-in-out, $custom-forms-transition);
            outline: 2px solid $white;
            @media screen and (max-width: 575px) {
                width: $custom-switch-indicator-size - 0.5rem;
                height: $custom-switch-indicator-size - 0.5rem;
            }
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &::after {
            background-color: $teal;
            transform: translateX($custom-switch-width - $custom-switch-indicator-size + 0.125rem);
        }
    }

    .custom-control-input:disabled {
        & ~ .custom-control-label::before {
            @include gradient-bg(lighten($gray-550, 20%));
        }
        & ~ .custom-control-label::after {
            @include gradient-bg(lighten($gray-550, 20%));
        }
        &:checked ~ .custom-control-label::before {
            @include gradient-bg($custom-control-indicator-checked-disabled-bg);
        }
        &:checked ~ .custom-control-label::after {
            @include gradient-bg($custom-control-indicator-checked-disabled-bg);
        }
    }
}
