@import 'styles/config';

.ProfilePageEmpty {
  align-items: center;
  font-size: 1.25rem;
  color: $text-muted;
  text-align: center;
  // margin: 10rem 0;
  font-weight: 100;
}
