@import 'styles/config';


.PlansPage {
    padding-top: $spacer * 6;

    @media screen and (max-width: 1440px) {
        padding-top: 80px;
    }

    @media screen and (max-width: 1240px) {
        padding-top: 70px;
    }

    @media screen and (max-width: 768px) {
        padding-top: 60px;
    }

    @media screen and (max-width: 526px) {
        padding-top: 50px;
    }

    &__heading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        @media screen and (max-width: 1240px) {
            margin-bottom: 30px;
        }

        @media screen and (max-width: 526px) {
            margin-bottom: 20px;
        }

        &--text {
            font-size: 40px;
            line-height: 65px;
            font-family: $font-family-headings;
            color: $calendar-black;
            padding: 0px 20px !important;
            text-align: center;

            @media screen and (max-width: 1920px) {
                font-size: 40px;
                line-height: 70px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 38px;
                line-height: 68px;
            }

            @media screen and (max-width: 1240px) {
                font-size: 36px;
                line-height: 50px;
            }

            @media screen and (max-width: 768px) {
                font-size: 32px;
                line-height: 50px;
            }

            @media screen and (max-width: 526px) {
                font-size: 28px;
                line-height: 30px;
            }
        }

        &--sub-heading {
            font-size: 18px !important;
            text-align: center;

            @media screen and (max-width: 1920px) {
                font-size: 16px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 16px;
            }

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }

            @media screen and (max-width: 526px) {
                font-size: 12px;
            }
        }
    }

    &__paragraph {
        text-align: center;

        &--text {
            font-size: 24px;
            line-height: 34px;
            font-family: $font-family-sans-serif;
            color: $calendar-header-black;

            @media screen and (max-width: 1920px) {
                font-size: 22px;
                line-height: 34px;
            }

            @media screen and (max-width: 1440px) {
                font-size: 20px;
                line-height: 30px;
            }

            @media screen and (max-width: 1240px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}