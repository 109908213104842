@import 'styles/config';

$PlainContentPage__section-spacer: $spacer * 3;

.PlainContentPage {
  padding-bottom: $PlainContentPage__section-spacer * 2;
}

.PlainContentPage__heading {
  font-size: 50px;
  margin-top: 60px ;
  margin-bottom: $spacer * 4;
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: bold;
}

.PlainContentPageSection__heading {
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: 20px;
  color: $calendar-blue !important;
}

.PlainContentPage__content {
  font-size: 18px;
}
