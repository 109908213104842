@import 'styles/config';

$ProfilePageSectionHeader-column-width: 10rem;

.ProfilePageSectionHeader {
    display: block;
    //margin-bottom: 1.5625rem;
    margin-bottom: 4.25rem;
    margin-top: 9.25rem;

    @include media-breakpoint-up(lg) {
        display: flex;
    }

    @media screen and (max-width: 1920px) {
        margin-bottom: 1.25rem;
        margin-top: 3.25rem;
    }

    @media screen and (max-width: 1050px) {
        margin-bottom: 2.25rem;
        margin-top: 4.25rem;
    }

    @media screen and (max-width: 426px) {
        margin-bottom: 1.25rem;
        margin-top: 2.25rem;
    }

    &__left-col {
        width: 100%;
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            max-width: $ProfilePageSectionHeader-column-width;
        }
    }

    &__center-col {
        width: 100%;

        @include media-breakpoint-up(lg) {
            display: block;
            width: 100%;
        }
    }

    &__right-col {
        width: 100%;
        text-align: center;
        margin-top: 1.25rem;

        @include media-breakpoint-up(lg) {
            max-width: $ProfilePageSectionHeader-column-width;
            text-align: right;
        }
    }

    &__title {
        font-size: 2.08rem;
        font-weight: 300;
        margin-bottom: 0;
        color: $calendar-black;

        @media screen and (max-width: 1920px) {
            font-size: 30px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 28px;
        }

        @media screen and (max-width: 1050px) {
            font-weight: 600;
            font-size: 22px;
        }
    }

    &__description {
        color: $calendar-header-black;
        margin-bottom: 0 !important;
        display: block !important;
        font-size: 1rem;

        @include media-breakpoint-up(sm) {
            color: $calendar-header-black;
            display: block !important;
            font-size: 1.5rem;
        }
    }
}