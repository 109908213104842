.WebinarPreviewModal {
  .modal-dialog {
    min-width: 90vw !important;
    width: 90vw !important;
  }

  .modal-content {
    height: 75vh;
    background-color: transparent;
    border: transparent;
    box-shadow: none;
  }

  &__body {
    background-color: transparent;
  }

  &__player-wrapper {
    position: relative;
    height: 100%;
    border-radius: 5px;
  }

  &__video-player {
    border-radius: 0.98rem !important;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}
