@import 'styles/config';

.FixedFeesLabel {
  &__icon {
    width: 21px;
    height: 27px;

    &--lg {
      width: 22px;
      height: 28px;
    }
  }

  &__text {
    &--lg {
      font-size: 18px;
      font-weight: bold;
      @media screen and (max-width: 1920px) {
        font-size: 16px;
      }

      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
    }
  }
}
