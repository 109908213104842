@import 'styles/config';

.HomePageFeatured__section {
    text-align: center;
    margin-top: $spacer * 12;
    margin-bottom: $spacer * 5;
}

.HomePageFeatured__header {
    margin-bottom: $spacer * 8;
}

.MaxWidthAdjustment {
    max-width: 95% !important;
    margin-top: 8rem !important;

    @media screen and (max-width:526px) {
        margin-top: 2rem !important;
    }
}