@import 'styles/config';

.BadgeFilter {
    height: 35px;
    margin-left: 10.5px;
    width: fit-content;
    border: 1px solid $gray-E9;
    padding: 8px 23px;
    display: flex;
    align-items: center;
    border-radius: 28px;
    color: $calendar-header-black;
    font-size: 14px;
    font-family: $font-family-sub-headings;
    line-height: 19px;
    cursor: pointer;
    background: none;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
        padding: 8px 16px;
        margin-left: 8.5px;
    }

    &--active {
        background: $calendar-header-black !important;
        padding: 2.5px 23px;
        color: $white !important;
    }
}

.BadgeFilterForResource {
    height: 35px;
    margin-left: 10.5px;
    width: fit-content;
    border: 1px solid $gray-E9;
    padding: 8px 23px;
    display: flex;
    align-items: center;
    border-radius: 28px;
    color: $calendar-header-black;
    font-size: 12px;
    font-family: $font-family-sub-headings;
    line-height: 19px;
    cursor: pointer;
    margin-bottom: 10px;
    background: white;

    &--active {
        background: $calendar-header-black !important;
        padding: 2.5px 23px;
        color: $white;
    }
}

@media screen and (max-width: 426px) {

    .BadgeFilter {
        height: 35px;
        margin-right: 8px;
        margin-left: 0;
        width: fit-content;
        border: 1px solid $gray-E9;
        padding: 6px 18px;
        display: flex;
        align-items: center;
        border-radius: 28px;
        color: $calendar-header-black;
        font-size: 12px;
        font-family: $font-family-sub-headings;
        line-height: 19px;
        cursor: pointer;

        &--active {
            background: $calendar-header-black;
            padding: 6px 18px;
            color: $white;
        }
    }

}
