@import 'styles/config';

.BootstrapModal {
  &__header {
    padding: 1.5rem 1rem !important;
    display: flex !important;
    align-items: center !important;

    &--title {
      line-height: 1.875rem !important;
      font-family: $font-family-headings !important;
    }

    &--cancel-icon {
      height: 20px;
      width: 20px;
      text-align: center;
      transform: rotate(45deg);
      color: $calendar-header-black;

      path {
        fill: $calendar-header-black;
      }
    }
  }
}
