@import 'styles/config';

.PodcastView {
    &__card {
        box-shadow: 0px 3px 60px rgb(0 0 0 / 5%) !important;
        border-radius: 11px !important;

        &--body {
            padding: 3rem !important;
            @media screen and (max-width: 526px) {
                padding: 2rem !important;
            }
        }
    }

    &__container {
        background-color: #f0f0f0;
        padding: 4rem 2rem;
        min-height: 100vh;
        position: relative;
    }

    &__header {
        display: flex;
        row-gap: 25px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        @media screen and (max-width: 992px) {
            flex-direction: column;
            align-items: center;
        }
        &--Image {
            width: 355px;
            height: 195px;
            overflow: hidden;
            margin-right: 29px;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 992px) {
                margin-right: 0px;
            }

            &--poster {
                width: fit-content;
                height: 100%;
                object-fit: cover;
                border-radius: 15px;

                &--none {
                    height: 195px;
                    width: 355px;
                    border-radius: 15px;
                    background-color: $gray-E9;
                }
            }
        }

        &__Details {
            width: 100%;
            min-width: 355px;
            max-width: calc(100% - 24rem);
            display: flex;
            flex-direction: column;
        }

        &--row-TU {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &--row-tag {
            margin-left: 0;
            margin-right: 0;
            min-width: 300px;
            width: calc(100% - 300px);

            @media screen and (max-width: 992px) {
                width: 100%;
            }
            @media screen and (max-width: 1069px) {
                margin-bottom: 10px;
            }
        }

        &--row-tagcontainer {
            justify-content: flex-start !important;
            @media screen and (max-width: 992px) {
                justify-content: center !important;
            }
        }

        &__userDetail {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            @media screen and (max-width: 1069px) {
                display: none !important;
            }

            &--name {
                font-size: 18px;
                line-height: 27px;
                font-family: $font-family-sans-serif;
            }

            &--image {
                padding-right: 1.2rem;
            }
        }

        &--title {
            font-size: 24px;
            line-height: 35px;
            font-family: $font-family-headings;
            padding-bottom: 1.4rem !important;
            text-align: unset !important;
            @media screen and (max-width: 992px) {
                font-size: 20px;
                text-align: center !important;
            }
        }

        &--description {
            font-size: 14px;
            line-height: 28px;
            font-family: $font-family-sans-serif;
            color: $calendar-header-black;
        }
    }

    &__Content {
        &--heading {
            display: flex;
            flex-direction: row;
            padding-bottom: 1.4rem !important;

            &--left {
                margin-right: auto;
            }

            &--right {
                width: fit-content;
                text-align: right;
            }
        }
    }

    &__tag {
        height: 37px;
    }

    &__margin {
        &--mt-20 {
            margin-top: 1.25rem;
        }
    }
}

.display--block {
    display: none;
    justify-content: flex-start !important;
    @media screen and (max-width: 992px) {
        justify-content: center !important;
    }
    @media screen and (max-width: 1069px) {
        display: flex !important;
    }
}
