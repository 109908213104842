@import 'styles/config';

.ProfilesPage {
  padding-top: $spacer * 8;
  position: relative;
  z-index: 0;
  // overflow-x: hidden;

  @media screen and (min-width: 1600px) {
    max-width: 1688px !important;
  }

  &__load-more-buttonPb {
    padding-bottom: 100px;
    padding-top: 10px;
  }

  &__load-more-button {
    font-size: 1.5rem;
    padding-top: 36px;
    padding-bottom: 36px !important;
    color: $calendar-black;

    @include hover-focus-active() {
      color: $calendar-black;
      text-decoration: none;
    }
  }

  &__card-deck-wrapper {
    margin: $spacer auto $spacer auto;
  }

  &__card-deck {
    justify-items: center;
    justify-content: center;
  }

  &__card {
    width: 18rem;
    height: auto;
    margin: 1rem 1rem 2rem !important;
    flex: auto !important;

    @include media-breakpoint-down(sm) {
      margin: 0 auto 2rem;
    }
  }

  &__profile_card_search {
    @include media-breakpoint-down(sm) {
      overflow-x: hidden;
      padding: 0px 20px;
    }
  }

  &__profile_card_container {
    @media screen and (max-width: 450px) {
      padding-top: 5px;
      height: calc(100vh - 154px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  &__profile_card_container_inner {
    @media screen and (max-width: 520px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media screen and (min-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@media screen and (max-width: 576px) {
  .ProfilesPage {
    padding-top: 20px;
    // overflow-x: hidden;
  }
}

@media screen and (max-width: 426px) {

  .mobilePostView {
    display: flex;
    justify-content: center;
  }

  .ProfilesPage {
    padding-top: 20px;
    // overflow-x: hidden;

    // padding-left: 20px !important;
    // padding-right: 20px !important;

    @media screen and (min-width: 1600px) {
      max-width: 1688px !important;
    }

    &__load-more-button {
      font-size: 1.5rem;
      padding-top: 36px;
      color: $calendar-black;

      @include hover-focus-active() {
        color: $calendar-black;
        text-decoration: none;
      }
    }

    &__card-deck-wrapper {
      margin: $spacer auto $spacer auto;
    }

    &__card-deck {
      justify-items: center;
      justify-content: center;
    }

    &__card {
      width: 18rem;
      height: auto;
      margin: 1rem 1rem 2rem !important;
      flex: auto !important;

      @include media-breakpoint-down(sm) {
        margin: 0 auto 2rem;
      }
    }
  }
}