@import 'styles/config';

.GuideToChoosingSymbolsCard {
  padding-bottom: 30px;
  height: 100%;

  &__card-body {
    border-radius: 15px;
    background-color: $gray-F9;
    min-height: 400px !important;
    max-width: 530px;
    border: 1px solid $gray-E9;

    @media screen and (min-width: 1200px) and (max-width: 1600px) {
      min-height: 480px !important;
    }

    @media screen and (min-width: 970px) and (max-width: 1200px) {
      min-height: 600px !important;
    }

    @media screen and (min-width: 767px) and (max-width: 970px) {
      min-height: 500px !important;
    }

    @media screen and (max-width: 767px) {
      min-height: 450px !important;
    }
  }

  &__header {
    margin-bottom: 27px;
  }

  &__image {
    height: 67px;
    width: 67px;
    object-fit: contain;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    color: $calendar-black;
    font-family: $font-family-headings;
    margin-bottom: 27px;

    @media screen and (min-width: 575px) and (max-width: 767px) {
      height: 60px;
    }
  }

  &__description {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 32px;
    color: $calendar-black;
    font-family: $font-family-sans-serif;
    text-align: justify;
  }
}
