@import '../../../../styles/config';

.DropdownSelect {
    &--block {
        display: block;
    }

    &__caret {
        color: $text-muted;
    }

    // toggle button
    &__toggle {
        text-align: left;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        line-height: 1;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $gray-700;
            outline: none !important;
            box-shadow: none !important;
        }

        // pill variant
        &--pill {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid $gray-E9;
            padding: 9px 15px;
            border-radius: 28px;
            min-width: 135px;
            height: 36px;
            justify-content: space-between;
            font-size: 14px;
            font-family: $font-family-sub-headings;
            color: $calendar-black;
        }
    }

    &__menu {
        //width: 100%;

        &--pill {
            border: 1px solid #e9e9e9 !important;
            border-radius: 15px !important;
            box-shadow: none !important;
            font-size: 14px !important;
            font-family: $font-family-sub-headings;
            //min-width: 100% !important;
            max-height: 300px;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none; /* Safari and Chrome */
            }

            .dropdown-item {
                padding: $dropdown-item-padding-y * 1.5 $dropdown-item-padding-x !important;

                &:hover,
                &:focus,
                &:active,
                &.active {
                    background: #fff !important;
                    color: $blue !important;
                }
            }
        }

        &::before {
            border-bottom: 9px solid $dropdown-border-color;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            content: '';
            display: inline-block;
            left: 86px;
            position: absolute;
            top: -9px;
        }

        &::after {
            border-bottom: 8px solid $white;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            content: '';
            display: inline-block;
            left: 86px;
            position: absolute;
            top: -7px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 12px !important;
        }
    }
}
