@import 'styles/config';

.ReviewCardControls {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: row;

  &__dropdown-toggle {
    display: block;

    &,
    &:hover {
      border-bottom: none !important;
    }
  }

  &__dropdown-menu {
    width: 100px;
    border: 1px !important;
    border-radius: 10px !important;

    &::after {
      border-left: 6px solid $white; /* Pointer will be on the right */
      border-top: 6px solid rgba(0, 0, 0, 0);
      border-bottom: 6px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      position: absolute;
      left: 100%; /* Position it at the right edge of the dropdown */
      top: 12px; /* Adjust this value to vertically align the arrow */
    }

    &:hover {
      background-color: white !important;
      border: 0 !important;
    }
  }

  &__dropdown-item {
    font-size: 14px !important;
    color: $gray-550 !important;
    padding-bottom: 11px !important;
    padding-top: 11px !important;
  }

  &__icon1 {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin: 0 8px 4px 0;
    cursor: pointer;
  }
}