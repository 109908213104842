@import 'styles/config';

.InteractiveLogo {

  & canvas {
    max-width: 541px;

    @media screen and (max-width: 1920px) {
      max-width: 450px;
    }

    @media screen and (max-width: 1440px) {
      max-width: 350px;
    }

    @media screen and (max-width: 768px) {
      max-width: 250px;
    }

    @include media-breakpoint-down(md) {
      max-width: 400px;

      @media screen and (max-width: 526px) {
        max-width: 295px;
      }
    }
  }
}
