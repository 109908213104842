@import 'styles/config';

.PricingPlans {
    padding: 150px 0px;

    @media screen and (max-width: 1440px) {
        padding: 90px 0px;
    }

    @media screen and (max-width: 1240px) {
        padding: 60px 0px;
    }

    @media screen and (max-width: 768px) {
        padding: 50px 0px;
    }

    @media screen and (max-width: 526px) {
        padding: 40px 0px;
    }

    &__title {
        font-size: 40px;
        font-weight: 600;
        color: #151C2D;
        margin-bottom: 60px;
        text-align: center;
        line-height: 76px;
        font-family: $font-family-headings;
        color: $calendar-black;

        @media screen and (max-width: 1920px) {
            font-size: 40px;
            line-height: 70px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 38px;
            line-height: 68px;
        }

        @media screen and (max-width: 1240px) {
            font-size: 36px;
            line-height: 50px;
        }

        @media screen and (max-width: 768px) {
            font-size: 32px;
            line-height: 50px;
        }

        @media screen and (max-width: 526px) {
            font-size: 28px;
            line-height: 30px;
        }
    }
}