@import 'styles/config';

.PostCard {
    &__card-body {
        z-index: 2;
        padding: 0px !important;
    }

    &__author {
        display: flex;
        align-items: center;
        color: $body-color;

        @include hover-focus-active() {
            color: $body-color;
            text-decoration: none;
        }
    }

    &__read-more-link {
        text-transform: uppercase;
        font-weight: 500;
    }

    &__content {
        padding-bottom: 28px;
        cursor: pointer;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        // height: 60px;
        @media screen and (max-width: 1050px) {
            padding-bottom: 20px;
        }
    }

    &__summary {
        overflow: hidden;
        height: 80px !important;

        @media screen and (max-width: 1050px) {
            font-size: 13px;
        }
    }

    &__summary--front {
        font-size: 0.9375rem; // 15px
    }

    &__footer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 0.5rem; // 8px
    }

    &__footer--front {
        font-size: 0.75rem; // 12px
    }

    &__footer-item {
        flex-basis: 0;
        flex-grow: 1;
        font-size: 0.7rem;
    }

    &__footer-item--center {
        flex-basis: unset;
        flex-grow: 0;
    }

    @media screen and (max-width: 426px) {
        &__card-body {
            z-index: 2;
        }

        &__author {
            display: flex;
            align-items: center;
            margin-top: 10px;
            color: $body-color;

            img {
                width: 30px;
                height: 30px;
            }

            @include hover-focus-active() {
                color: $body-color;
                text-decoration: none;
            }
        }

        &__read-more-link {
            text-transform: uppercase;
            font-weight: 500;
        }

        &__content {
            padding-bottom: 0px;
            // height: 60px;
        }

        &__summary {
            font-size: 12px !important;
            line-height: 20px !important;
            margin-bottom: 5px;
            overflow: hidden;
        }

        &__summary--front {
            font-size: 0.9375rem; // 15px
        }

        &__footer {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-size: 0.5rem; // 8px
        }

        &__footer--front {
            font-size: 0.75rem; // 12px
        }

        &__footer-item {
            flex-basis: 0;
            flex-grow: 1;
            font-size: 0.7rem;
        }

        &__footer-item--center {
            flex-basis: unset;
            flex-grow: 0;
        }
    }
}