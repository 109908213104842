@import 'styles/config';

.ConfirmationModal {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  &__header.modal-header {
    border-bottom: none;
  }

  &__body.modal-body {
    text-align: center;
    padding-bottom: $modal-header-padding-y * 2;
  }

  &__footer {
    padding: 0 !important;
    overflow: hidden;
    border: none;

    > :first-child {
      margin: 0;
      width: 50%;
      border-radius: 0;
    }

    > :last-child {
      margin: 0;
      width: 50%;
      border-radius: 0;
    }
  }

  &__button.btn {
    @include button-variant($white, $gray-250);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
