@import 'styles/config';

.PostView {
    &__bg--image {
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: rgba(0, 0, 0, 0.35);
        background-blend-mode: color;
        padding-bottom: 4.398rem;
        background-size: cover;
    }

    &__content {
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &--html {
            margin: 1rem;
            text-align: left;
        }

        &--container {
            width: 60%;
            margin: auto;
            @media screen and (max-width: '992px') {
                width: 80%;
            }
        }

        &--title {
            text-align: center;
            font-size: 60px;
            color: white;
            font-family: $font-family-sub-headings;
            padding: 3rem 0;
            margin: 0;
            @media screen and (max-width: '992px') {
                font-size: 30px;
            }
        }

        &--user-detail {
            &--pImage {
                border: 2px solid white;
            }

            &--link {
                display: flex;
                align-items: center;
                color: $white;

                &:hover {
                    color: $white;
                }
            }

            &--author {
                font-family: $font-family-sub-headings;
                font-size: 1.5rem;
                text-align: left;
                @media screen and (max-width: '992px') {
                    font-size: 1rem;
                }
            }
            &--date {
                @media screen and (max-width: '992px') {
                    font-size: 0.8rem;
                }
            }
        }
    }

    &__tags-content {
        background-color: $gray-F9;

        &--text {
            font-family: $font-family-sub-headings;
        }
    }

    &__edit-post-btn {
        padding-top: 2rem;
        padding-right: 2rem;
        text-align: right;

        svg {
            @media screen and (max-width: '992px') {
                width: 24px;
                height: 24px;
            }
            path {
                fill: $white;
            }
        }
    }

    &__type {
        text-align: center;
        margin: 5rem 0;
    }
}
