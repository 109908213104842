@import 'styles/config';

.ProfilePageEditButton {
  &__icon {
    width: 30px;
    height: 30px;

    &--sm {
      width: 1.75rem;
      height: 1.75rem;
    }

    @media screen and (max-width: 1920px) {
      width: 22px;
      text-align: right;
    }

    @media screen and (max-width: 1050px) {
      width: 20px;
      text-align: right;
    }
  }
}