@import 'styles/config';

.ArticleCard {
  margin-bottom: 1.9rem;
  cursor: pointer;

  text-decoration: none;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }

  &--box {
    width: 100%;
    min-height: 200px;
    background-color: $white;
    border-radius: 15px;
    border: 1px solid $gray-E9;
    box-shadow: rgba(17, 17, 26, 0.1) 12px 12px 80px;
  }

  &__headingBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__update-text {
    color: $calendar-header-black;

    @media screen and (max-width: 800px) {
      position: relative;
    }
  }

  &--mainBox {
    padding: 40px;
    display: flex;
    flex-direction: column;
  }

  &--heading {
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
    font-family: $font-family-headings;
    color: $body-color;
  }

  &--description {
    padding-top: 14px;
    font-size: 16px;
    font-family: $font-family-sans-serif;
    color: $calendar-header-black;
  }

  &--profileName {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 22px;
  }

  &--profileText {
    font-size: 14px;
    color: $calendar-light-green;
    font-weight: 700;
    padding-left: 5px;
  }
}
