.card {
  box-shadow: $card-box-shadow;
}

.card-grid {
  width: 100%;

  @media (max-width: 360px) {
    justify-content: center;
  }
}
