@import 'styles/config';

.TagControl {
  &__control {
    border-radius: $input-border-radius !important;
    padding: $input-padding-y 0 $input-padding-y $input-padding-x;
    border-color: $input-border-color;
  }

  &__value-container {
    padding-left: 0 !important;
  }

  &__menu {
    z-index: $zindex-dropdown !important;
  }
}
