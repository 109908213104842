@import 'styles/config';

.BookingModalHeader {
  &__name {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__submit-button {
    width: 310px;
    height: 55px;
    font-size: 1rem;
    border-radius: 13px !important;
    background: linear-gradient(90deg, rgba(10, 139, 242, 1) 0%, rgba(1, 200, 238, 1) 100%);
    font-weight: bold !important;
    font-family: $font-family-sans-serif;
  }

  &__availability {
    font-size: 1rem;
    color: $gray-550;
  }

  &__duration {
    font-size: 18px;
    color: $gray-550;
    font-weight: 300 !important;
  }
}

.header-profile {
  position: relative;
}

.header-profile-online {
  position: absolute;
  bottom: 3px;
  right: 3px;
  background-color: #00D515;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 2px solid white;
}