@import 'styles/config';

.FAQ {
  &__title {
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0;
  }

  &__toggle {
    text-decoration: none !important;
    color: black !important;
  }
}
