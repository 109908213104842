@import 'styles/config';

.ProfilePicture {
    width: $spacer * 3.75;
    // height: $spacer * 3.75;
    max-width: 100%;

    background-color: $gray-200;

    &--dark-placeholder {
        background-color: $gray-400;
    }

    &--placeholder {
        display: inline-block;
    }

    &--xs {
        width: $table-image-size;
        height: $table-image-size;
    }

    &--xs.ProfilePicture--border {
        border-width: 2px;
    }

    &--notification {
        width: 37px;
        height: 37px;
    }

    &--sm {
        width: 3.375rem; // 54px
        height: 3.375rem; // 54px
    }

    &--xsm {
        width: 4.875rem; // 78px
        height: 4.875rem; // 78px
    }

    &--sm.ProfilePicture--border {
        border-width: 2px;
    }

    &--xmd {
        width: $spacer * 6.5;
        height: $spacer * 6.5;
    }

    &--lg {
        width: $spacer * 13;
        height: $spacer * 13;
    }

    &--lg-sm {
        width: $spacer * 10;
        height: $spacer * 10;
    }

    &--mp {
        width: 16.75rem;
        height: 16.75rem;
    }

    &--ADI {
        width: 56px;
        height: 56px;
    }

    &--ld {
        width: 78px;
        height: 78px;
    }

    &--Mobile {
        aspect-ratio: 1;
        max-width: 140px;
        min-width: 100px;
        width: 100%;
    }

    &--xl {
        width: $spacer * 15;
        height: $spacer * 15;
    }

    &--xxl {
        width: $spacer * 25.7;
        height: $spacer * 25.7;

        @include media-breakpoint-down(sm) {
            width: $spacer * 15;
            height: $spacer * 15;
        }
    }

    &--round {
        border-radius: 50%;
    }

    &--table {
        vertical-align: middle;
    }

    &--border {
        border: 6px solid white;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    }

    &--align-text-center {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ProfilePictureError__icon {
    flex: auto;
    color: $danger;
    font-size: $font-size-base;
}

.Profileimage1 {
    margin-bottom: 57px;
}