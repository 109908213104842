@import 'styles/config';

.HomePageReview {
  &__heading {
    font-size: 50px;
    max-width: 800px;
    text-align: center;
    margin-bottom: 50px;

    @media screen and (max-width: 1920px) {
      font-size: 40px;
      margin-bottom: 55px;
    }

    @media screen and (max-width: 1440px) {
      font-size: 38px;
      margin-bottom: 45px;
    }
  }

  &--topmargin {
    margin-top: 130px;

    @media screen and (max-width:1920px) {
      margin-top: 80px;
    }
  }

  &__mainheading {
    display: flex;
    justify-content: center;
  }

  &__section {
    background-color: #F9F9F9;
    margin-top: 20px;
    padding-bottom: 60px;
  }

}

@media screen and (max-width:526px) {

  .HomePageReview {
    &__heading {
      font-size: 24px;
      max-width: 800px;
      margin-bottom: 24px;
    }

    &__mainheading {
      display: flex;
      justify-content: center;
    }

    &--topmargin {
      margin-top: 50px;
    }

    &__section {
      background-color: #F9F9F9;
      margin-top: 40px;
      padding-bottom: 60px;
    }

  }

}