@import 'styles/config';

$MediaCard__spacer: $spacer * 0.8;

.MediaCard {
  &.card {
    border: 1px solid $gray-E9;
    text-align: left;
    transition: all 0.15s ease-in-out;
    max-width: 80%;
  }

  &::before {
    transition: all 0.4s;
    position: absolute;
    content: '';
    width: 100.9%;
    height: 50%;
    left: 49.6%;
    margin-left: -49.9999%;
    top: 26%;
    border-color: rgba(200, 200, 200, 0);
    border-style: solid;
    border-width: 0 2px;
  }

  &::after {
    transition: all 0.4s;
    position: absolute;
    content: '';
    width: 50%;
    height: 100.5%;
    left: 49.99999999%;
    margin-left: -25%;
    top: 0;
    border-color: rgba(200, 200, 200, 0);
    border-style: solid;
    border-width: 2px 0;
  }

  &:hover {
    cursor: pointer;

    &::after {
      width: 100.1%;
      margin-left: -50%;
      border-radius: 15px;
      top: -2px;
      border-color: #5a5a5a;
    }

    &::before {
      height: 99.7%;
      top: 1px;
      border-radius: 15px;
      border-color: #5a5a5a;
    }
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: $MediaCard__spacer;
    margin-bottom: $MediaCard__spacer;
  }

  &__tags {
    // text-transform: uppercase;
    height: 45px;
    overflow-x: auto;
    white-space: nowrap;
    text-align: left;
    // margin-left: 20px;
    // margin-right: 20px;
  }

  &__tags::-webkit-scrollbar {
    display: none;
  }

  &__tag {
    margin: 2px $spacer / 6;
    padding: 8px 23px !important;
    font-size: 14px !important;
    font-family: $font-family-headings;
    line-height: 19px !important;
    color: $white !important;

    &:last-child {
      margin-right: 0;
    }
  }

  &__footer {
    border-top: 1px solid $gray-200;
    padding-top: $card-spacer-x;
    margin-top: 0;


    &--dashed {
      border-top: 1px dashed $gray-200;
    }
  }
}

@media screen and (max-width: 426px) {
  .MediaCard {
    border: 1px solid $gray-E9;
    margin: 1px auto 0;
    text-align: left;
    transition: all 0.15s ease-in-out;
    max-width: 80%;

    &::before {
      transition: all 0.4s;
      position: absolute;
      content: '';
      width: 100.9%;
      height: 50%;
      left: 49.6%;
      margin-left: -49.9999%;
      top: 26%;
      border-color: rgba(200, 200, 200, 0);
      border-style: solid;
      border-width: 0 2px;
    }

    &::after {
      transition: all 0.4s;
      position: absolute;
      content: '';
      width: 50%;
      height: 100.5%;
      left: 49.99999999%;
      margin-left: -25%;
      top: 0;
      border-color: rgba(200, 200, 200, 0);
      border-style: solid;
      border-width: 2px 0;
    }

    &:hover {
      &::after {
        width: 100.1%;
        margin-left: -50%;
        border-radius: 15px;
        top: -2px;
        border-color: #5a5a5a;
      }

      &::before {
        height: 99.7%;
        top: 1px;
        border-radius: 15px;
        border-color: #5a5a5a;
      }
    }
    &__title {
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: $MediaCard__spacer;
      margin-bottom: $MediaCard__spacer;
    }

    &__tags {
      text-transform: capitalize;
      height: 45px;
      overflow-x: auto;
      white-space: nowrap;
      text-align: left;
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      // width: auto;
    }

    &__tags::-webkit-scrollbar {
      display: none;
    }

    &__tag {
      margin: 2px $spacer / 6;
      padding: 6px 21px !important;
      font-size: 8px !important;
      line-height: 19px !important;
      color: $white !important;

      &:last-child {
        margin-right: 0;
      }
    }

    &__footer {
      border-top: 1px solid $gray-200;
      padding-top: 5px;
      margin-top: 0;

      &--dashed {
        border-top: 1px dashed $gray-200;
      }
    }
  }
}
