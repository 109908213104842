@import 'styles/config';

.TimezoneControl {
  padding: 0.45rem 1.5rem;
  border-radius: 13px !important;
  box-shadow: none !important;
  border: 1px solid #e9e9e9 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  margin-right: 2rem;
}
