@import 'styles/config';

.HomepageSlider{
    width: 100%;
height: 700px;
position: relative;
@media screen and (max-width:1400px) {
    max-height: 550px;
}  
@media screen and (max-width:950px) {
    max-height: 400px;
}   
@media screen and (max-width:500px) {
    max-height: 300px;
}   

    &--background{
        background-color: $gray-F9;
        height: 500px;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: -2;
        @media screen and (max-width:1400px) {
            max-height: 400px;
        }  
        @media screen and (max-width:950px) {
            max-height: 300px;
        }  
        @media screen and (max-width:500px) {
            max-height: 200px;
        } 
    }

.slick-list {
    position: relative;
    max-height: 648px;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    @media screen and (max-width:1400px) {
        max-height: 500px;

    }    
}

    .slick-dots{
        background: transparent;
        .slick-active{
            button {
                background: linear-gradient(
                    270deg
                    ,#01c8ee 0,#0a8bf2);
                border-radius: 50%;
                width: 15px !important;
                height: 15px!important;
               
            }
        }
    }
    .slick-dots li button:before{
        content: "";
    }

    .slick-dots li button {
        background-color: #e9e9e9 !important;
        border-radius: 50%;
        width: 15px !important;
        height: 15px!important;
       
    }
}


