@import 'styles/config';

$main-navbar__spacer-sm: $spacer;
$main-navbar__spacer: $main-navbar__spacer-sm * 2;

.AppNavbar {
    @include box-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.05));
    padding: 1.2rem 0;

    &__dropdown-toggle {
        display: block;

        &,
        &:hover {
            border-bottom: none !important;
        }
    }

    &__dropdown-menu {
        width: 215px;
        border: 0 !important;
        border-radius: 10px !important;

        // little arrow/triangle at the top of the dropdown menu when open
        &::after {
            border-bottom: 6px solid $white;
            border-left: 6px solid rgba(0, 0, 0, 0);
            border-right: 6px solid rgba(0, 0, 0, 0);
            content: '';
            display: inline-block;
            right: 1.875rem;
            position: absolute;
            top: -5px;
        }

        &:hover {
            background-color: white !important;
            border: 0 !important;
        }
    }

    &__dropdown-item {
        font-size: 14px !important;
        color: $gray-550 !important;
        padding-bottom: 11px !important;
        padding-top: 11px !important;
    }

    &__button {
        height: 3.125rem;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 8px !important;

        @media screen and (max-width: 320px) {
            height: 2.5rem !important;
            font-size: 0.75 * $font-size-base !important;
        }

        &--fixed-width {
            width: 128px;

            @media screen and (max-width: 320px) {
                width: 100px !important;
            }
        }

        &--fixed-width-lg {
            padding-left: 1.875rem !important;
            padding-right: 1.875rem !important;
        }
    }

    &__col {
        display: flex;
        align-items: center;

        &--nav-items {
            flex-grow: 1;
        }

        &--nav-items-right {
            justify-content: flex-end;
            padding-right: 0 !important;
        }

        &--nav-items-collapse {
            display: none;

            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }

        &--toggle {
            display: flex;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        &--brand {
            display: none;
            margin: auto;

            @include media-breakpoint-up(lg) {
                display: block;
                text-align: center;
            }
        }
    }

    &__aside {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: $gray-900;
        overflow-x: hidden;
        padding-top: 60px;
        transition: 0.5s;

        &--open {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
        }

        &--close {
            display: none;
        }
    }

    &__aside-close {
        color: $gray-400;
        padding: $spacer;
        font-size: 1.5rem;
        // align-self: flex-end;
        display: flex;
        justify-content: space-between;
        background: white;
        width: 320px;
        align-items: center;
        // &:hover {
        //     color: black;
        // }
    }

    &__backdrop {
        position: fixed;
        z-index: 99;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        // background-color: rgb(0, 0, 0);
        // background-color: rgba(0, 0, 0, 0.2);
        transform: translateX(-100%);
    }

    &__aside-divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid #e9ecef;
    }

    &__aside {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: $gray-900;
        overflow-x: hidden;
        padding-top: 60px;
        transition: 0.5s;

        &--open {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }

        &--close {
            display: none;

        }

        &__nav-items {
            text-transform: uppercase;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &__col--nav-items-is-open {
            flex-direction: column;
            display: flex;
            justify-content: space-evenly;

            a,
            a:hover {
                color: #e9ecef;
                font-size: 2rem;
            }
        }
    }

    &__aside-divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid #e9ecef;
    }

    &__nav-items {
        text-transform: uppercase;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__col--nav-items-is-open {
        flex-direction: column;
        display: flex;
        justify-content: space-evenly;
    }

    &__divider {
        display: inline-block;
        height: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        width: 4px;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__nav-item {
        padding: 0 $main-navbar__spacer;

        &--link {
            margin: 0 1rem;
            padding: 0.5rem;

            // width: fit-content;
            @media screen and (min-width: 1300px) {
                margin: 0 $main-navbar__spacer !important;
            }
        }

        &--right {
            padding: 0 2rem;
        }
    }

    &__link {
        color: $text-muted;
        text-transform: uppercase;
        font-weight: 500;
        font-family: $font-family-sub-headings;
        border-bottom: 1px solid transparent;

        &:hover {
            color: $text-muted;
            text-decoration: none;
            border-bottom: 1px solid $text-muted;
        }

        &--active {
            border-bottom: 2px solid #0990f2;
            padding: 0.5rem;
            color: #0990f2;

            &:hover {
                color: #0990f2 !important;
                text-decoration: none !important;
                border-bottom: 2px solid #0990f2;
            }
        }

        @media screen and (max-width: 992px) {
            &--active {
                color: #0a8bf2;
                border-bottom: none;
                background: #f2f9fe;

                &:hover {
                    color: #0a8bf2 !important;
                    text-decoration: none !important;
                }
            }
        }
    }

    &__brand {
        height: 40px;
        object-fit: contain;
        width: 100%;
    }

    &__dropdown-name {
        text-transform: none;
        font-size: 16px;
        color: #151c2d;
    }

    &__network-dropdown {

        .btn-primary {
            margin: 0 2rem;
            padding: 0.5rem 0;
            color: #788B9A !important;
            background-color: transparent !important;
            border: none;
            box-shadow: none !important;
            border-bottom: 1px solid transparent !important;
            transition: none;
            text-transform: uppercase;
            font-weight: 500;
            border-radius: 0 !important;
            position: relative;

            &[aria-expanded="true"] {
                .DropdownCaret {
                    rotate: -180deg;
                }
            }

            &::after {
                content: "";
                height: 1px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #788B9A;
                width: 0;
                transition: width 200ms ease-out, opacity 110ms ease-out;
                opacity: 0;
            }

            &[aria-expanded="true"]::after {
                width: 100%;
                opacity: 1;
            }

            &:hover {
                background-color: transparent;
                color: #788B9A;
            }

            .DropdownCaret {
                color: #788B9A !important;
                transition: rotate 200ms ease;
            }
        }

        .dropdown-menu {
            width: 535px;
            padding: 0;
            border: none;
            display: flex !important;
            column-gap: 10px;
            box-shadow: 0px 3px 60px 0px #0000000D;
            transform: translate(32px, 51px);
            transition: opacity 110ms ease-out;


            .dropdown__triangle {
                position: absolute;
                left: 16px;
                top: -10px;
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #FFFFFF;
                box-shadow: 0px 3px 60px 0px #0000000D;
            }

            .dropdown__navlinks {
                width: 100%;
                padding: 10px;
                padding-right: 0;
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                border-radius: 11px 0 0 11px;

                .dropdown-item.active {
                    background-color: #F9F9F9;

                    &:hover {
                        .dropdown__header {
                            p {
                                scale: 1;
                            }
                        }
                    }

                    .dropdown__header {
                        p {
                            color: #151C2D;
                            font-weight: 600;
                        }
                    }

                    .dropdown__info {
                        color: #151C2D;
                    }
                }

                .dropdown-item {
                    font-size: 14px;
                    color: #788B9A;
                    font-weight: 500;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 4px;
                    background-color: transparent;
                    border-radius: 10px;

                    &:hover {
                        background-color: #F9F9F9;

                        .dropdown__header {
                            p {
                                scale: 1.01;
                                font-weight: 600;
                                color: #151C2D;
                            }
                        }

                        .dropdown__info {
                            color: #151C2D;
                        }
                    }

                    .dropdown__header {
                        display: flex;
                        align-items: center;

                        p {
                            color: #788B9A;
                            transition: color 200ms ease-out, scale 100ms ease-out, font-weight 100ms ease;
                        }

                        svg {
                            width: 15px;
                            height: 15px;
                            margin-right: 10px;
                            color: #788B9A;
                        }
                    }

                    .dropdown__info {
                        margin-left: 25px;
                        font-size: 12px;
                        color: #788B9A;
                        font-weight: 500;
                        white-space: wrap;
                        transition: color 200ms ease-out;
                    }
                }
            }

            .dropdown__slack-community {
                width: 100%;
                max-width: 252px;
                background-color: #0990F21A;
                border-radius: 0 11px 11px 0;
                padding: 30px 20px;

                .slack__header {
                    display: flex;
                    column-gap: 10px;

                    svg {
                        width: 22px;
                        height: 22px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        color: #151c2d;
                    }
                }

                .slack__description {
                    margin: 20px 0;
                    font-size: 14px;
                    color: #788B9A;
                }

                a {
                    color: #0990F2;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    column-gap: 4px;
                    text-decoration: none;
                    border-bottom: 1px solid transparent;
                    transition: border-color 250ms ease-out, border-width 350ms ease-out;

                    &:hover {
                        border-bottom: 2px solid #0990F2;
                    }

                    svg {
                        width: 13px;
                        height: 13px;
                    }
                }
            }
        }

        .dropdown-menu.show {
            opacity: 1;
        }

        @media screen and (max-width: 992px) {
            display: none;
        }
    }
}

.CloseIcon {
    cursor: pointer;

    &:hover {
        color: black;
    }
}

.Mobile {
    padding: 20px 20px;
    border-bottom: transparent;
    margin: 0px;

    &:hover {
        border-bottom: transparent !important;
    }
}

.AppMobileMenu {
    display: flex;
    height: 100%;
    filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.051));

    &__content {
        color: black;
        width: 320px;
        display: flex;
        flex-direction: column;
        background: white;
    }

    &__overlay {
        width: calc(100% - 320px);
    }
}