@import 'styles/config';

.GameChangerSection {
  &__title {
    font-size: 3rem;
  }

  &__strong {
    color: #06A4F0;
  }

  &__negative {
    margin-top: -10rem;

    @include media-breakpoint-down(md) {
      margin-top: -5rem;
    }
    @include media-breakpoint-down(sm) {
      margin-top: inherit;
    }
  }

  &__icon-wrapper {
    margin: 10rem 0;
    @include media-breakpoint-down(sm) {
      margin: 0 0 5rem 0;
    }
  }

  &__icon {
    width: 5rem;
    height: 5rem;
    margin: 0 0 2rem 0;
    @include media-breakpoint-down(sm) {
      margin: 4rem 0 0 0;
    }
  }

  &__button-book {
    margin: 8rem 0;
  }
}
