@import 'styles/config';

.ResourceSearchBar {
  &__input-group {
    margin: $spacer * 5 0;
  }

  &__btn {
    border-radius: 1.375rem;
  }
}
