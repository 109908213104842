@import 'styles/config';

.PopularQuestions {
  background-color: $gray-F9;
  padding: 7.69rem 8.75rem;
  margin-bottom: 3.93rem;

  &__article-group {
    display: flex;
    flex-wrap: wrap;
  }

  &__article {
    padding: 1.125rem 1.82rem;
    background-color: $white;
    margin: 0 1.25rem 1.88rem 0;
    border: 1px solid $gray-E9;
    border-radius: 0.8rem;
    font-size: 1.125rem;
    color: $calendar-header-black;
    line-height: 1.5rem;

    &:active, &:hover, &:focus {
      text-decoration: none;
      color: $calendar-header-black;
    }
  }
}
